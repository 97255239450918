import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { SosLoaderService } from './loader.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'serviceos-ng-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class SosLoaderComponent implements OnDestroy {
  @Input()
  loaderName = 'master';

  private _showLoaderOnNavigation = false;

  private navigationSubscription: Subscription | null = null;

  private navigationLoading: any = null;

  @Input()
  public set showLoaderOnNavigation(value: boolean) {
    if (value == false) {
      this.navigationLoading && this.sosLoaderService.hideLoader(this.navigationLoading);
    }
    this._showLoaderOnNavigation = value;
  }

  constructor(private sosLoaderService: SosLoaderService, private router: Router) {
    this.sosLoaderService.setLoaderRef(this);
    this.subscribeForNavigation();
  }

  ngOnDestroy(): void {
    this.navigationLoading && this.sosLoaderService.hideLoader(this.navigationLoading);
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  public subscribeForNavigation() {
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (this._showLoaderOnNavigation) {
        if (event instanceof NavigationStart) {
          this.navigationLoading = this.sosLoaderService.showLoader();
        }
        if (event instanceof NavigationEnd) {
          // Simulate loading because some pages are loading so fast and no loading appear at all
          setTimeout(()=> {
            this.navigationLoading && this.sosLoaderService.hideLoader(this.navigationLoading);
          }, 600)
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        // if (event instanceof NavigationCancel) {
        //   this.navigationLoading && this.sosLoaderService.hideLoader(this.navigationLoading);
        // }
        
        // if (event instanceof NavigationError) {
        //   this.navigationLoading && this.sosLoaderService.hideLoader(this.navigationLoading);
        // }

      }
    })
  }

  public settings: { text: string; overlayEnabled: boolean, isInvisible: boolean } = {
    text: '',
    overlayEnabled: true,
    isInvisible: false,
  };

  public isHidden = true;

  public show(loaderText: string, overlayEnabled = true, isInvisible: boolean): void {
    this.settings.text = loaderText;

    this.settings.overlayEnabled = overlayEnabled;

    if (!isInvisible) {
      this.isHidden = false;
    }
  }

  public hide(): void {
    this.isHidden = true;
  }
}
