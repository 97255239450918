import {
  Choice,
  ChoiceItem,
  ChoiceItemValueChange,
} from './../../../../typings';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ChoiceValidationService } from '../choice-validation.service';

@Component({
  selector: 'serviceos-ng-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.scss'],
  providers: [ChoiceValidationService],
  encapsulation: ViewEncapsulation.None,
})
export class ChoiceComponent implements OnInit {
  @Input()
  public settings!: Choice;

  @Input()
  public parentSettings: Choice | ChoiceItem | undefined;

  @Input()
  public validationsPatterns = {};

  @Output()
  public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

  public validChoice = false;
  @Output()
  public valid: EventEmitter<boolean> = new EventEmitter();

  private choiceComponentIsDisplayed = false;

  constructor(private choiceValidationService: ChoiceValidationService) {}

  ngOnInit(): void {
    this.choiceValidationService.setValidationPatterns(
      this.validationsPatterns
    );
  }

  ngAfterViewInit(): void {
    // FIX: Expression has changed after it was checked
    setTimeout(() => {
      this.choiceComponentIsDisplayed = true;
      this.validateChoice();
    });
  }

  public onChangeChoiceItemValue(event) {
    this.validateChoice();
    this.changeChoiceItemValue.emit(event);
  }

  private validateChoice() {
    if (this.choiceComponentIsDisplayed) {
      this.validChoice = this.choiceValidationService.validateChoice(
        this.settings
      );
      this.valid.emit(this.validChoice);
    }
  }
}
