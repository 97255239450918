import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    Choice,
    ChoiceItem,
    ChoiceItemValueChange,
} from './../../../../../typings';

@Component({
    selector: 'serviceos-ng-alert-item',
    templateUrl: './alert-item.component.html',
    styleUrls: ['./alert-item.component.scss'],
})
export class AlertItemComponent implements OnInit {
    @Output()
    public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

    @Input()
    public settings!: ChoiceItem;

    @Input()
    public serviceCustomize;

    @Input()
    public parentInstance!: Choice | ChoiceItem;

    constructor() {}

    ngOnInit(): void {}
}
