import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'serviceos-ng-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input()
  type: 'primary' | 'secondary' = 'primary';

  @Input()
  size: 'normal' | 'small' = 'normal';

  @Input()
  font_weight: 'regular' | 'semi-bold' = 'regular';

  constructor() { }

  ngOnInit(): void {
    
  }

}
