import { AlertModule } from './../shared/base/alert/alert.module';
import { ChoicesModule } from './../shared/choices/choices.module';
import { ButtonModule } from './../shared/base/button/button.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';
import { SystemIconModule } from '../shared/base/system-icon/system-icon.module';
import { LocalLoaderModule } from '../local-loader/local-loader.module';

@NgModule({
  declarations: [
    FormComponent,
  ],
  imports: [
    CommonModule,
    ChoicesModule,
    ButtonModule,
    SystemIconModule,
    LocalLoaderModule,
    AlertModule
  ],
  exports: [
    FormComponent,
  ]
})
export class DynamicFormModule { }
