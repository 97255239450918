import { UnitI } from './units.state';

export namespace UnitsActions {
  export class ToggleFavorite$ {
    static readonly type = `[UNITS] Toggle Favorite`;
    constructor(
      public data: UnitI,
      public queryParams: {
        'query[booking_id]'?: number;
        'query[transaction_id]'?: number;
      }
    ) {}
  }

  export class FetchSingleUnit$ {
    static readonly type = `[UNITS] Fetch Unit Data`;
    constructor(
      public data: UnitI,
      public queryParams: {
        'query[booking_id]'?: number;
        'query[transaction_id]'?: number;
      }
    ) {}
  }

  export class Reset {
    static readonly type = `[UNITS] Reset`;
  }
}
