import { NgxsOnInit, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EXPIRING_DATA_STATE_TOKEN } from './expiring-data.const';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ExpiringDataActions } from './expiring-data.actions';
import { LocalStorageService } from '../../common/services/local-storage/local-storage.service';
import { Environment } from '../../common';

export class ExpiringDataStateModel {
    global: { [key: string]: any } = {};
    application: { [key: string]: any } = {};
}

@State<ExpiringDataStateModel>({
    name: EXPIRING_DATA_STATE_TOKEN,
    defaults: new ExpiringDataStateModel(),
})
@Injectable()
export class ExpiringDataState implements NgxsOnInit {
    applicationLocalStorageKey = '_expiring_data';
    globalLocalStorageKey = 'global_expiring_data';

    propExpireTimeout: any = null;

    constructor(
        private store: Store,
        private env: Environment,
        private localStorageService: LocalStorageService
    ) {}

    ngxsOnInit(ctx?: StateContext<any>) {
        ctx?.dispatch(new ExpiringDataActions.Initialize());
    }

    @Action(ExpiringDataActions.Initialize)
    initialize({ patchState }: StateContext<ExpiringDataStateModel>) {
        this.applicationLocalStorageKey =
            this.env.project.replace('/s/g', '_') +
            this.applicationLocalStorageKey;

        const global =
            this.localStorageService.getItem(this.globalLocalStorageKey) || {};
        const application =
            this.localStorageService.getItem(this.applicationLocalStorageKey) ||
            {};

        const state = this.propertyCleanUp({ global, application });
        this.updateLocalStorage(state);
        patchState(state);
    }

    @Action(ExpiringDataActions.Update)
    update({ patchState, getState }: StateContext<ExpiringDataStateModel>) {
        const state = getState();
        const newState = this.propertyCleanUp(state);
        this.updateLocalStorage(newState);
        patchState(newState);
    }

    @Action(ExpiringDataActions.SetProperty)
    setProperty(
        { patchState, getState }: StateContext<ExpiringDataStateModel>,
        payload: ExpiringDataActions.SetProperty
    ) {
        const state = getState();

        let expireDate: any;
        if (payload.validity !== 'forever') {
            try {
                const currentTime = new Date();

                const validityArr = payload.validity.split(',');
                const validity = validityArr.reduce((sum, current) => {
                    if (current.includes('d')) {
                        return sum + parseInt(current) * 24 * 60 * 60 * 1000;
                    } else if (current.includes('h')) {
                        return sum + parseInt(current) * 60 * 60 * 1000;
                    } else if (current.includes('m')) {
                        return sum + parseInt(current) * 60 * 1000;
                    }

                    return sum;
                }, 0);

                expireDate = currentTime.setTime(
                    currentTime.getTime() + validity
                );
            } catch (error) {}
        } else if (payload.validity === 'forever') {
            expireDate = 'forever';
        }

        if (!expireDate) {
            throw new Error('Validity format is wrong.');
        }

        const propValue = {
            key: payload.propName,
            expireDate: expireDate,
            value: payload.value,
        };

        let newState = new ExpiringDataStateModel();
        if (payload.storeIn == 'global') {
            newState.global = {
                ...state.global,
                [payload.propName]: propValue,
            };
        }

        newState.application = {
            ...state.application,
            [payload.propName]: propValue,
        };

        newState = this.propertyCleanUp(newState);
        this.updateLocalStorage(newState);
        patchState(newState);
    }

    @Action(ExpiringDataActions.RemoveProperty)
    removeProperty(
        { patchState, getState }: StateContext<ExpiringDataStateModel>,
        payload: ExpiringDataActions.RemoveProperty
    ) {
        const state = getState();
        let newState = new ExpiringDataStateModel();

        newState.application = { ...state.application };
        newState.global = { ...state.global };

        // Remove the prop
        if (newState.application[payload.propName]) {
            delete newState.application[payload.propName];
        } else if (newState.global[payload.propName]) {
            delete newState.global[payload.propName];
        }

        newState = this.propertyCleanUp(newState);
        this.updateLocalStorage(newState);
        patchState(newState);
    }

    @Action(ExpiringDataActions.UpdateProperty)
    updateProperty(
        { patchState, getState }: StateContext<ExpiringDataStateModel>,
        payload: ExpiringDataActions.UpdateProperty
    ) {
        const state = getState();
        let newState = new ExpiringDataStateModel();

        newState.application = { ...state.application };
        newState.global = { ...state.global };

        // Update the prop
        if (newState.application[payload.propName]) {
            newState.application = {
                ...newState.application,
                [payload.propName]: {
                    ...newState.application[payload.propName],
                    value: payload.value,
                },
            };
        } else if (newState.global[payload.propName]) {
            newState.global = {
                ...newState.global,
                [payload.propName]: {
                    ...newState.global[payload.propName],
                    value: payload.value,
                },
            };
        }

        newState = this.propertyCleanUp(newState);

        this.updateLocalStorage(newState);
        patchState(newState);
    }

    private updateLocalStorage(state: ExpiringDataStateModel) {
        this.localStorageService.setItem(
            this.applicationLocalStorageKey,
            state.application
        );
        this.localStorageService.setItem(
            this.globalLocalStorageKey,
            state.global
        );
    }

    /**
     * Read all savedProps and clean property with expire date.
     * @param state
     */
    private propertyCleanUp(
        state: ExpiringDataStateModel
    ): ExpiringDataStateModel {
        const updatedExpiringData = new ExpiringDataStateModel();

        const isDateExpire = (date) => {
            if (date == 'forever') {
                return false;
            }

            const expireDate: Date = new Date(date);

            if (new Date() > expireDate) {
                return true;
            }
            return false;
        };

        let mostSoonDate = 0;

        Object.keys(state.application).forEach((key) => {
            const prop = state.application[key];
            if (!isDateExpire(prop.expireDate)) {
                mostSoonDate =
                    prop.expireDate != 'forever' &&
                    mostSoonDate < prop.expireDate
                        ? prop.expireDate
                        : mostSoonDate;
                updatedExpiringData.application[key] = prop;
            }
        });
        Object.keys(state.global).forEach((key) => {
            const prop = state.application[key];
            if (!isDateExpire(prop.expireDate)) {
                mostSoonDate =
                    prop.expireDate != 'forever' &&
                    mostSoonDate < prop.expireDate
                        ? prop.expireDate
                        : mostSoonDate;
                updatedExpiringData.application[key] = prop;
            }
        });

        this.setNextPropUpdate(mostSoonDate);

        return updatedExpiringData;
    }

    private setNextPropUpdate(date: Date | number) {
        if (date == 0) {
            return;
        }

        const currentDate = new Date().getTime();
        const nextExpireDate = new Date(date).getTime();

        clearTimeout(this.propExpireTimeout);

        this.propExpireTimeout = setTimeout(() => {
            
            this.store.dispatch(new ExpiringDataActions.Update())
        },
        nextExpireDate - currentDate + 50);
    }
}
