import { NgxsAfterBootstrap, NgxsOnInit } from '@ngxs/store';
import { SosApiService } from '../../services/api/api.service';
import { Selector, StateContext } from '@ngxs/store';
import { Action } from '@ngxs/store';
import { Injectable, Injector } from '@angular/core';
import { State } from '@ngxs/store';
import { LocalizationState } from './localization/localization.state';
import { ThemeState } from './theme/theme.state';
import { TrackingState } from './tracking/tracking.state';
import { CoreActions } from './core.actions';
import { first, switchMap, tap } from 'rxjs/operators';
import { FeaturesState } from './features/features.state';
import { FeaturesActions } from './features/features.actions';
import { ModulesState } from './modules/modules.state';
import { ModulesActions } from './modules/modules.actions';
import { CORE_API_PATHS, CORE_STATE_TOKEN } from './core.const';
import { ErrorActions } from '../error-store/error.actions';
import { ThemeActions } from './theme/theme.actions';
import { LocalizationActions } from './localization/localization.actions';
import { forkJoin, throwError } from 'rxjs';
import { ModalsService } from '../../../elements';
import { DefaultModalConfig } from '../../../models/default-modal';
import { Environment } from '../../environment/environment';
import { ValidationsActions } from './validations/validations.actions';


export interface CoreStateModel {
    application: any | null;
    status?: 'pending' | 'error' | 'success';
}

@State<CoreStateModel>({
    name: CORE_STATE_TOKEN,
    defaults: {
        application: null,
        status: 'pending',
    },
    children: [
        TrackingState,
        ThemeState,
        LocalizationState,
        FeaturesState,
        ModulesState,
    ],
})
@Injectable()
export class CoreState implements NgxsOnInit {
    constructor(
        private SosApiService: SosApiService,
        private modalService: ModalsService,
        private env: Environment
    ) {}

    ngxsOnInit(ctx: StateContext<CoreStateModel>) {

        ctx.dispatch(new CoreActions.InitializeCoreStore$()).subscribe(
            () => {},
            (error) => {
                this.modalService
                    .open(
                        new DefaultModalConfig(
                            '',
                            error.error && error.error.length && error.error[0]?.message ? error.error[0]?.message : 'Oops! Seems like something went wrong on our end! Please accept our apologies and try again.',
                            [
                                {
                                    title: 'Ok',
                                    type: 'close',
                                },
                            ],
                            'error'
                        ),
                        { backdropClickClose: false }
                    )
                    .close$.pipe(first())
                    .subscribe((res) => {
                        window.location.reload();
                    });
            }
        );
    }

    @Action(CoreActions.GetSingleModule)
    getSingleModule(
        { dispatch }: StateContext<CoreStateModel>,
        payload: CoreActions.GetSingleModule
    ) {
        let pathName = '';
        if (payload.pathName) {
            pathName = payload.pathName + '/';
        }
        return this.SosApiService.get(CORE_API_PATHS.configuration + '/' + pathName + CORE_API_PATHS.configuration_include).pipe(
            tap(
                (response) => {
                    dispatch(
                        new ModulesActions.SetModule(response.data, payload.storeName)
                    )
                },
                (error) => {
                    //...
                }
            )
        );
    }

    @Action(CoreActions.InitializeCoreStore$)
    InitializeCoreStore({
        dispatch,
        patchState,
    }: StateContext<CoreStateModel>) {
        return this.SosApiService.get(CORE_API_PATHS.configuration + CORE_API_PATHS.configuration_include).pipe(
            tap(
                (response) => {

                    if (this.env?.version) {
                        console.debug('[DEBUG | SOS WA] ' + this.env.type + ' ' + this.env.version);
                    }

                    // TODO this need to be change when new configurations come and update RateBookingModal
                    // patchState({ rate_service_screens: response.data.rate_service_screens })

                    const configuration = response.data,
                        modules: any = {};

                    let applicationConfig = configuration.application;

                    // new structure - modules at top level of configuration
                    if (applicationConfig) {
                        // Check which modules are enabled from application module settings and extract them from top level configuration structure
                        if (applicationConfig.settings && applicationConfig.settings.modules && applicationConfig.settings.modules.length) {
                            applicationConfig.settings.modules.forEach((moduleName: string) => {
                                if (configuration[moduleName] || configuration?.modules?.[moduleName]) {
                                    modules[moduleName] = configuration[moduleName] ? configuration[moduleName] : configuration?.modules?.[moduleName];
                                } else {
                                    throw new Error('Module not found in configutaion: ' + moduleName);
                                }
                            });
                        }
                    } else if (configuration.modules) { // old structure  - configuration->modules
                        // Ensure to extract modules from old deprecated config (configuration.modules) if newest structure with top level modules are missing
                        applicationConfig = configuration.modules.application;

                        applicationConfig.settings.modules.forEach((moduleName: string) => {
                            if (configuration.modules?.[moduleName]) {
                                modules[moduleName] = configuration.modules[moduleName];
                            } else {
                                throw new Error('Module not found in configutaion.modules: ' + moduleName);
                            }
                        });
                    }

                    patchState({
                        application: applicationConfig,
                        status: 'success',
                    });

                    const shortCodes = {
                        brand_name: applicationConfig.settings.brand_name,
                        referral_bonus_formatted: response.data.referral_bonus_formatted
                    };

                    // Init default modules/stores and dispatch main actions

                    if (Object.keys(modules).length) {
                        Object.assign(modules, {
                            application: applicationConfig
                        });

                        forkJoin([
                            dispatch(new LocalizationActions.SetShortcodes(shortCodes)),
                            dispatch(
                                new ModulesActions.SetModules(modules)
                            ),
                            dispatch(
                                new ThemeActions.SaveThemeColors(
                                    applicationConfig.theme
                                )
                            ),
                            dispatch(
                                new LocalizationActions.AddSupportedLanguages(
                                    applicationConfig.settings.languages
                                )
                            ).pipe(
                                switchMap(() =>
                                    dispatch(
                                        new LocalizationActions.AddModulesTexts(
                                            modules
                                        )
                                    )
                                )
                            ),
                            dispatch(
                                new ValidationsActions.Initialize()
                            )
                        ]).subscribe(() => {
                            dispatch(
                                new FeaturesActions.SuccessfulFetchConfig()
                            );
                        });
                    } else {
                        dispatch(
                            new ErrorActions.Custom(
                                '[CRITICAL] Missing modules inside configuration'
                            )
                        );
                    }

                    
                },
                (error) => {
                    patchState({ application: null, status: 'error' });
                }
            )
        );
    }
}
