<div class="dynamic-form">

    <serviceos-ng-alert class="error-alert" *ngIf="errorMessage" [type]="'error'">
        {{errorMessage}}
    </serviceos-ng-alert>

    <div class="form-container">
        <ng-container *ngIf="choices[currentChoiceIndex] as choice">
            <div class="form-question">
                <serviceos-ng-choice [settings]="choice" (changeChoiceItemValue)="onChoiceValueChange()"
                    (valid)="onValid($event)">
                </serviceos-ng-choice>
            </div>
            <div class="form-choice-additional-description" *ngIf="choice.customize?.additional_description">
                <p *ngIf="choice.customize.additional_description">
                    {{ choice.customize.additional_description }}
                </p>
            </div>
            <div class="form-choice-terms" *ngIf="choice.customize?.terms">
                <div class="terms-holder" *ngIf="choice.customize?.terms?.description">
                    <serviceos-ng-system-icon class="sos-icon" [icon]="'info-circle'"></serviceos-ng-system-icon>
                    <div>
                        <span [innerHTML]="choice.customize.terms.description"></span>
                        &nbsp;
                        <span><a target="_blank" href="{{ choice.customize.terms.policy.link }}">{{
                                choice.customize.terms.policy.title }}</a></span>
                    </div>
                </div>
            </div>

            <div class="form-navigation">
                <ng-container *ngFor="let action of choice.actions">
                    <ng-container
                        *ngIf="choice.customize?.actions_button_styles && choice.customize?.actions_button_styles[action.type] as styleType">
                        <serviceos-ng-button (click)="onFormAction(action)" [isFullWidth]="true" [type]="styleType"
                            [disable]="action.type === 'on_submit' && !choiceValid"> {{ action.title }}
                        </serviceos-ng-button>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <serviceos-ng-local-loader [showLoader]="showLoader"></serviceos-ng-local-loader>
</div>