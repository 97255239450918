import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorState } from './error.state';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ErrorState])
  ],
  declarations: [],
})
export class SosErrorStoreModule {}
