import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'serviceos-ng-system-icon',
  templateUrl: './system-icon.component.html',
  styleUrls: ['./system-icon.component.scss'],

})
export class SystemIconComponent implements OnInit {
  @Input()
  icon = '';

  constructor() {}

  ngOnInit(): void {}
}
