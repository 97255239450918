import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ScriptService } from 'ngx-script-loader';
import { fromEvent, of } from 'rxjs';
import { catchError, map, take, finalize, tap } from 'rxjs/operators';
import { SosCoreConfig } from '../../../core-config';
import { SosLoaderService } from '../../../elements/loader/loader.service';
import { getObfClientLoadedSubscription } from '../../../helpers';
import { AccountActions } from '../../../stores-modules/account-store/account.actions';
import { BookingsActions } from '../../../stores-modules/bookings-store/bookings/bookings.actions';

@Injectable({
    providedIn: 'root',
})
export class ObfControllerService {
    /**
     * <script type='text/javascript' src='https://skeleton.dutio.com/widgets/v2/client.js?app_token=unq9fzg66d4gk09t8w9ipzvbo0ddxk6htamvlsxnd33vh68oufie0yqawea1zn2d'></script><noscript> Your browser doesn't support JavaScript or you have disabled JavaScript. </noscript>
     *
     * Events:
     * bookingTransaction // Booking status.
     * obf-init-error
     * obf-client-loaded
     * obf-status // open / close
     */

    private scriptLoad = false;

    constructor(
        private config: SosCoreConfig,
        private scriptLoaderService: ScriptService,
        private loaderService: SosLoaderService,
        private store: Store
    ) {
        fromEvent(window.document, 'obf-status')
            .pipe(map((event: any) => event?.detail))
            .subscribe((event) => {
                if (event.message == 'close') {
                    this.store.dispatch(new AccountActions.Fetch()).subscribe();
                }
            });

        fromEvent(window.document, 'bookingTransaction')
            .pipe(map((event: any) => event?.detail))
            .subscribe((event) => {
                if (event.message == 'complete') {
                    this.store
                        .dispatch(new BookingsActions.GetAll(false))
                        .subscribe();
                }
            });
    }

    initialize() {
        const xrmDomain = this.config.api_url.split('/api')[0];

        // NOTE Skip obf_loading if is fantastic services or uspaah for them is load from angular-layout.blade.php
        if (this.config.obf_loaded) {
            return;
        }

        this.scriptLoaderService
            .loadScript(
                xrmDomain + `/widgets/v2/client.js?app_token=${this.config.key}`
            )
            .pipe(
                take(1),
                map(() => true),
                catchError(() => of(false))
            )
            .subscribe((isLoader) => {
                this.scriptLoad = isLoader;
            });
    }

    /**
     * This method is private because for now we going to use helper.
     * This way we dont need to integrate this opening method inside module.
     * @returns
     */
    private open() {
        // DONT USE IT until we dont find better way.
        const loader = this.loaderService.showLoader();
        return getObfClientLoadedSubscription().pipe(
            finalize(() => {
                this.loaderService.hideLoader(loader);
            }),
            tap(() => {
                (window as any).obfClientSDK.obfInstanceFunctions.openObf({});
            })
        );
    }
}
