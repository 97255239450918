<ng-container [ngSwitch]="unitType">
    <!-- TEAM PART -->
    <ng-container *ngSwitchCase="'team'">
        <serviceos-ng-team [unitInfo]="data"
            (onToggleFavorite)="toggleFavorite($event)" [texts]="texts"></serviceos-ng-team>
    </ng-container>

    <!-- UNIT PART -->
    <ng-container *ngSwitchCase="'unit'">
        <serviceos-ng-pro [unitInfo]="data"
            (onToggleFavorite)="toggleFavorite($event)" [texts]="texts"></serviceos-ng-pro>
    </ng-container>
</ng-container>
<serviceos-ng-local-loader [showLoader]="showLoader"></serviceos-ng-local-loader>
