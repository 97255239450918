import { Injectable } from '@angular/core';
import { FEATURES_STATE_TOKEN } from './features.const';
import { Action, Selector, State, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { FeaturesActions } from './features.actions';
import { CoreSelectors } from '../core.selectors';

export class FeaturesStateModel {
  public token: string;
  public status: 'initialize' | 'define' = 'define'

  constructor(token) {
    this.token = token;
  }
}

/**
 * TODO do i relay need this ??? And if not better remove and change the code where use this to make it simple.
 */
@State<FeaturesStateModel[]>({
  name: FEATURES_STATE_TOKEN,
  defaults: [],
})
@Injectable()
export class FeaturesState {
  coreConfigInit = false;

  constructor(private store: Store) {

  }

  @Action(FeaturesActions.Register)
  Register({ setState, getState, dispatch }: StateContext<FeaturesStateModel[]>, payload) {
    const state = getState();
    setState([
      ...state,
      new FeaturesStateModel(payload.token)
    ]);

    dispatch(FeaturesActions.InitializeFeatures);
  }

  @Action(FeaturesActions.InitializeFeatures)
  InitializeFeatures({ setState, getState, dispatch }: StateContext<FeaturesStateModel[]>) {
    if (this.coreConfigInit) {
      const state = JSON.parse(JSON.stringify(getState()));
      
      const updatedStates =state.filter(feature => feature.status === 'define').map(feature => {
        dispatch({ type: `[${feature.token}] Initialize` })
        feature.status = 'initialize';
        return feature;
      })
      
      setState([...state.filter(originalFeature => !updatedStates.find(updateFeature=> originalFeature.token === updateFeature.token)), ...updatedStates]) 
    }
  }

  @Action(FeaturesActions.SuccessfulFetchConfig)
  successfulFetchConfig({ dispatch }: StateContext<FeaturesStateModel[]>) {
    this.coreConfigInit = true;
    dispatch(FeaturesActions.InitializeFeatures);
  }
}
