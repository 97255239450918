import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SERVICES_STATE_TOKEN, SERVICES_API_PATHS } from './services.const';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { XrmResourcesService } from '../../../common/services/xrm-resources/xrm-resources.service';
import { ServicesActions } from './services.actions';
import { ErrorActions, SosApiService } from '../../../common';

export class ServicesStateModel {
  services: [] = [];
}

@State<ServicesStateModel>({
  name: SERVICES_STATE_TOKEN,
  defaults: new ServicesStateModel(),
})
@Injectable()
export class ServicesState {
  public baseUrl = '';

  constructor(private sosApiService: SosApiService) {}

  @Action(ServicesActions.FetchAll)
  fetchAllServices({ dispatch, patchState }: StateContext<ServicesStateModel>) {
    return this.sosApiService
      .get(SERVICES_API_PATHS.services, { "paging[limit]": 9999 })
      .pipe(
        tap((response) => {
          patchState({ services: response.data });
        })
      );
  }

  @Action(ServicesActions.Reset)
  reset({ patchState }: StateContext<ServicesStateModel>) {
    patchState({ services: [] });
  }
}
