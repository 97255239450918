import { createSelector, Selector } from '@ngxs/store';
import { AuthorizationModel, AuthorizationState } from './authorization.state';

export class AuthorizationSelectors {
    @Selector([AuthorizationState])
    static getAuthorization(state: AuthorizationModel) {
        return state;
    }

    @Selector([AuthorizationState])
    static getSocialProviders(state: AuthorizationModel) {

        // DEBUG ONLY
        // return [
        //     {
        //         id: 2,
        //         type: "Email",
        //         name: "Sign in with E-mail",
        //         data: null,
        //         sort: 20
        //     },
        //     {
        //         id: 1,
        //         type: "Facebook",
        //         name: "Facebook",
        //         data: {
        //             app_id: "1063439521750619"
        //         },
        //         sort: 10
        //     },
        //     {
        //         id: 3,
        //         type: "Google",
        //         name: "Google",
        //         data: {
        //             client_i: "245881331255-evc0kul43kke843pnh7bq51cbo7mh3c9.apps.googleusercontent.com"
        //         },
        //         sort: 30
        //     },
        //     {
        //         id: 4,
        //         type: "Google",
        //         name: "Google",
        //         data: {
        //             client_i: "245881331255-evc0kul43kke843pnh7bq51cbo7mh3c9.apps.googleusercontent.com"
        //         },
        //         sort: 30
        //     },
        //     // {
        //     //     id: 2,
        //     //     type: "Email",
        //     //     name: "Email",
        //     //     data: null,
        //     //     sort: 20
        //     // }
        // ]

        return state?.socialProviders;
    }

    @Selector([AuthorizationState])
    static getMergeAccount(state: AuthorizationModel) {
        return state?.mergeAccount;
    }

    @Selector([AuthorizationState])
    static isAuthorize(state: AuthorizationModel) {
        return !!state?.sid;
    }

    static getSessionProperty(prop:string) {
        return createSelector([AuthorizationState], (state: AuthorizationModel) => {
            return state?.session[prop] || null;
        });
    }

    static getSocialProvider(providerType:string) {
        return createSelector([AuthorizationState], (state: AuthorizationModel) => {
            return state?.socialProviders?.filter((provider) => provider.type === providerType)[0];
        });
    }
}
