<div *ngIf="!socialAction else socialButton"
    [ngClass]="{ 'sos-loader-show': loader, 'full-width': isFullWidth, 'auto-width': type === 'tertiary' || isAutoWidth}"
    class="button-wrapper {{ size + '-size' }}">
    <button type="{{isSubmitButton ? 'submit': 'button'}}" class="{{ type }} {{ disable || inactive ? '' : state }}"
        appDynamicColorChanger [ngClass]="{'disabled': disable, 'inactive': inactive, 'with-text': additionalText}">
        <span [ngClass]="{'has-prefix': isIconAsPrefix, 'has-suffix': !isIconAsPrefix && iconName}"
            class="button-label">
            <ng-content>

            </ng-content>
            <span *ngIf="iconName" class="button-icon sos-icon {{ iconName }}"></span>
        </span>
    </button>
    <span *ngIf="additionalText" class="button-additional-text"> {{ additionalText }}</span>
</div>

<ng-template #socialButton>
    <div [ngSwitch]="type">
        <div class="sos-btn-wrap sos-google-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
            *ngSwitchCase="'Google'" [ngClass]="{ 'sos-loader-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="sos-btn sos-google"
                [ngClass]="{ 'sos-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="sos-icon-wrap">
                    <img class="sos-google-icon" src="https://files.dxr.cloud/kpdfKtMHEpCcQmp1UryWZFRhrDo6WS1kplazEVmYWI2NnLXdtTfZAnhw.svg" />
                </div>
                <span>{{ socialAction == 'login' ? socialLabels.login.googleLabel : socialLabels.register.googleLabel }}
                    <span class="button-type">{{ type }}</span></span>
            </button>
            <div class="sos-load-eclipse">
                <div class="sos-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="sos-btn-wrap sos-facebook-wrap" *ngSwitchCase="'Facebook'"
            [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }" [ngClass]="{ 'sos-loader-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="sos-btn sos-facebook"
                [ngClass]="{ 'sos-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="sos-icon-wrap">
                    <img class="sos-icon-facebook-new" src="https://files.dxr.cloud/chBCRHfPNqWwtBOPirbESSJI1wG3jq8pkrdgDxgydPFi5GaB2aO5ltMZ.svg" />
                </div>
                <span>{{ socialAction == 'login' ? socialLabels.login.facebookLabel : socialLabels.register.facebookLabel
                    }} <span class="button-type">{{ type }}</span></span>
            </button>
            <div class="sos-load-eclipse">
                <div class="sos-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="sos-btn-wrap sos-apple-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
            *ngSwitchCase="'Apple'" [ngClass]="{ 'sos-loader-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="sos-btn sos-apple"
                [ngClass]="{ 'sos-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="sos-icon-wrap">
                    <img class="sos-apple-icon" src="https://files.dxr.cloud/Dap8JOsmMVWfMfOOCxcOya1eaBDuOBqEeaknVD0QOuEMTLgpYfSzenV5.svg" />
                </div>
                <span>{{ socialAction == 'login' ? socialLabels.login.appleLabel : socialLabels.register.appleLabel }}
                    <span class="button-type">{{ type }}</span></span>
            </button>
            <div class="sos-load-eclipse">
                <div class="sos-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="sos-btn-wrap sos-microsoft-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
            *ngSwitchCase="'Microsoft'" [ngClass]="{ 'sos-loader-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="sos-btn sos-microsoft"
                [ngClass]="{ 'sos-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="sos-icon-wrap">
                    <img class="sos-microsoft-icon" src="https://files.dxr.cloud/rOXnHfAkfKTHYmrylSj7jp3D6punCuIxdSd1XZBjCSDHsBRghfcKeZwZ.svg" />
                </div>
                <span>{{ socialAction == 'login' ? socialLabels.login.microsoftLabel : socialLabels.register.microsoftLabel }}
                    <span class="button-type">{{ type }}</span></span>
            </button>
            <div class="sos-load-eclipse">
                <div class="sos-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="sos-btn-wrap sos-email-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
            *ngSwitchCase="'Email'" [ngClass]="{ 'sos-loader-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="sos-btn sos-email"
            appDynamicColorChanger [ngClass]="{'sos-disable': disable}" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <span [ngClass]="{'has-prefix': isIconAsPrefix, 'has-suffix': !isIconAsPrefix && iconName}"
                    class="button-label">
                    <div class="sos-icon-wrap">
                        <img class="sos-email-icon" src="https://files.dxr.cloud/rOXnHfAkfKTHYmrylSj7jp3D6punCuIxdSd1XZBjCSDHsBRghfcKeZwZ.svg" />
                    </div>
                    <span>{{ socialAction === 'login' ? socialLabels.login.emailLabel : socialLabels.register.emailLabel }}
                        <span class="button-type">{{ type }}</span></span>
                    <span *ngIf="iconName" class="button-icon sos-icon {{ iconName }}"></span>
                </span>
            </button>
            <!-- <button type="{{isSubmitButton ? 'submit': 'button'}}" class="sos-btn sos-email"
                [ngClass]="{ 'sos-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="sos-icon-wrap">
                    <img class="sos-email-icon" src="https://files.dxr.cloud/rOXnHfAkfKTHYmrylSj7jp3D6punCuIxdSd1XZBjCSDHsBRghfcKeZwZ.svg" />
                </div>
                <span>{{ socialAction === 'login' ? socialLabels.login.emailLabel : socialLabels.register.emailLabel }}
                    <span class="button-type">{{ type }}</span></span>
            </button> -->
        </div>

        <div class="sos-btn-wrap sos-oauth-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
            *ngSwitchCase="'OAuth2'" [ngClass]="{ 'sos-loader-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="sos-btn sos-oauth"
                [ngClass]="{ 'sos-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="sos-icon-wrap">
                    <img class="sos-oauth-icon" src="https://files.dxr.cloud/202404235m7V2TKGVLIL4kEl3RihNTlaziamVViku1eMPpLn1jxfgJj0E4mK" />
                </div>
                <span>{{ socialAction == 'login' ? socialLabels.login.oauthLabel : socialLabels.register.oauthLabel }}
                    <span class="button-type">{{ type }}</span></span>
            </button>
            <div class="sos-load-eclipse">
                <div class="sos-load-lds-eclipse"></div>
            </div>
        </div>
    </div>
</ng-template>