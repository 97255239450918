<div class="d-flex flex-column unit-tracking">
    <serviceos-ng-local-loader [showLoader]="showLocalLoader"></serviceos-ng-local-loader>

    <div class="progress-steps-holder">
        <div class="progress-steps" *ngIf="proTrackingData">
            <div class="progres-step d-flex" *ngFor="let step of proTrackingData.progress; let i = index"
                [ngClass]="{'current-complete':step.active, 'first': i==0, 'complete': trackingStatus !== 'disable'}">
                <span class="progress-step-icon"><img [src]="step.icon_url" alt=""> </span>
                <span class="dot">

                </span>
                <span class="title">
                    {{step.title}}
                </span>
            </div>
        </div>
    </div>

    <div class="map-holder">
        <div class="eta-holder" *ngIf="eta_message">
            {{ eta_message }}
        </div>
        <div id="map-error-message-wrap" class="justify-content-center align-items-center"
            [ngClass]="{'d-flex': trackingStatus === 'disable', 'd-none': trackingStatus !== 'disable'}">
            <div class="map-error-message">
                <p>
                    {{texts.map_wait_message}}
                </p>
                <serviceos-ng-link [font_weight]="'semi-bold'" (click)="updateData()">
                    {{texts.map_wait_link}}
                    <serviceos-ng-system-icon class="reload-icon" [icon]="'reload'"></serviceos-ng-system-icon>
                </serviceos-ng-link>
            </div>
        </div>

        <ng-container *ngIf="!(googleMapLoaded && proTrackingData); else googleMapTemplate">
            <div>
                <ngx-skeleton-loader [theme]="{ 
                    'height.px': 290,
                    'border-radius': '0px'
                }"></ngx-skeleton-loader>
            </div>
        </ng-container>

        <ng-template #googleMapTemplate>
            <div id="map-overlay"
                [ngClass]="{'d-block': trackingStatus === 'finished', 'd-none': trackingStatus !== 'finished'}">
            </div>

            <div>
                <google-map #googleMap [width]="'100%'" [height]="'300px'" [options]="googleOptionsMap">
                    <map-marker *ngIf="proTrackingData!.booking_address_coordinates"
                        [position]="proTrackingData!.booking_address_coordinates" [options]="googleOptionsClientMarker">
                    </map-marker>
                    <map-marker *ngIf="proTrackingData!.main_unit_location"
                        [position]="proTrackingData!.main_unit_location" [options]="googleOptionsProMarker">
                    </map-marker>
                    <map-directions-renderer *ngIf="directionsResults && trackingStatus === 'on_the_way'"
                        [directions]="directionsResults" [options]="googleOptionsPath">
                    </map-directions-renderer>
                </google-map>
            </div>
        </ng-template>
    </div>

    <div class="actions-holder d-flex">
        <div class="d-flex flex-column flex-grow-1">
            <span class="action-title">
                <p *ngIf="unitData">
                    {{texts.action_title}}
                </p>
            </span>
            <div class="unit-holder d-flex align-items-center ">
                <div *ngIf="unitData">
                    <img class="unit-img" [src]="unitData.photo" alt="">
                </div>
                <span class="unit-title" *ngIf="unitData">
                    {{unitData.name}}
                </span>
            </div>
        </div>
        <div class="action align-self-lg-end align-self-center opi" *ngIf="proTrackingData && proTrackingData.actions && proTrackingData.actions.length">
            <ng-container *ngFor="let action of proTrackingData.actions">
                <ng-container *ngIf="action.type !== 'direct_call'">
                    <span class="d-none d-lg-block">
                        <serviceos-ng-link [type]="'secondary'" (click)="deepLink(action)">
                            {{action.title}}
                        </serviceos-ng-link>
                    </span>
                    <serviceos-ng-button class="d-block d-lg-none" [type]="'secondary'"
                        (click)="deepLink(action)">{{action.title}}
                    </serviceos-ng-button>
                </ng-container>
                <ng-container *ngIf="action.type === 'direct_call'">
                    <span class="d-none d-lg-block">
                        <serviceos-ng-link [type]="'secondary'">
                            <a href="tel:{{getDirectPhone(action)}}" rel="nofollow">
                                {{action.title}}
                            </a>
                        </serviceos-ng-link>
                    </span>
                    <serviceos-ng-button class="d-block d-lg-none" [type]="'secondary'">
                        <a href="tel:{{getDirectPhone(action)}}" rel="nofollow">
                            {{action.title}}
                        </a>
                    </serviceos-ng-button>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>