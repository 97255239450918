import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ExpiringDataState } from './expiring-data.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([ExpiringDataState]),
  ],
})
export class ExpiringDataStoreModule {}
