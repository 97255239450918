import { SystemIconModule } from './../shared/base/system-icon/system-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingCardDetailsComponent } from './booking-card-details/booking-card-details.component';
import { ButtonModule } from '../shared/base/button/button.module';
import { LinkModule } from '../shared/base/link/link.module';
import { ShouldDisplayDeepLinkModule } from '../shared/base/should-display-deep-link/should-display-deep-link.module';
import { BookingCardDetailsFooterComponent } from './booking-card-details-footer/booking-card-details-footer.component';
import { BookingCardDetailsBodyComponent } from './booking-card-details-body/booking-card-details-body.component';
import { BookingCardDetailsHeaderComponent } from './booking-card-details-header/booking-card-details-header.component';
import { BookingCardDetailsViewSummaryComponent } from './booking-card-details-view-summary/booking-card-details-view-summary.component';
import { ImageColorFilterModule } from '../shared/base/image-color-filter/image-color-filter.module';

@NgModule({
  declarations: [BookingCardDetailsComponent, BookingCardDetailsFooterComponent, BookingCardDetailsBodyComponent, BookingCardDetailsHeaderComponent, BookingCardDetailsViewSummaryComponent],
  imports: [
    CommonModule,
    ButtonModule,
    ShouldDisplayDeepLinkModule,
    LinkModule,
    SystemIconModule,
    ImageColorFilterModule
  ],
  exports: [BookingCardDetailsComponent, BookingCardDetailsFooterComponent, BookingCardDetailsBodyComponent, BookingCardDetailsHeaderComponent, BookingCardDetailsViewSummaryComponent],
})
export class BookingCardDetailsModule {}
