import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'serviceos-ng-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input()
  public socialAction: string = '';

  @Input()
  public socialLabels = {
    login: {
      oauthLabel: 'Sign in with',
      appleLabel: 'Continue with',
      googleLabel: 'Sign in with',
      facebookLabel: 'Continue with',
      microsoftLabel: 'Sign in with',
      emailLabel: 'Sign in with Email'
    },
    register: {
      oauthLabel: 'Sign up with',
      appleLabel: 'Continue with',
      googleLabel: 'Sign up with',
      facebookLabel: 'Continue with',
      microsoftLabel: 'Sign up with',
      emailLabel: 'Sign up with'
    },
  };

  @Input()
  public isSubmitButton = false;

  @Input()
  public disable = false;

  @Input()
  public loader = false;

  @Input()
  public type:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'Google'
    | 'Facebook'
    | 'Apple' = 'primary';

  @Input()
  public state: 'default' | 'hover' | 'pressed' | 'disabled' | 'inactive' =
    'default';

  @Input()
  public size: 'small' | 'normal' | 'large' =
    'normal'; /** small | normal | large */

  @Input()
  public inactive: boolean = false;

  @Input()
  public iconName: string = ''; /** icon name from our font */

  @Input()
  public isIconAsPrefix: boolean = false;

  @Input()
  public additionalText: string = ''; /** small text placed under the button */

  @Input()
  public isFullWidth: boolean = false;

  @Input()
  public isAutoWidth: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
