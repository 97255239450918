/**
 * By given json and key(keys) will find the key value and switch it to string to fix problem when we have number longer from 15 characters.
 *
 *  let text = '{"normal":2.3,"long":123456789012345678901}';
 *
 *  JSON.parse will lose some digits and a whole number:
 *  console.log(JSON.stringify(JSON.parse(text)));
 *  '{"normal":2.3,"long":123456789012345680000}'  WHOOPS!!!
 *
 * @param jsonString
 * @param propKey single string key or array with keys.
 */
export function jsonLongNumberFix(
  jsonString: string,
  propKey: string | string[]
) {
  if (Array.isArray(propKey)) {
    propKey.forEach((key) => {
      findMaches(jsonString, key).forEach((match) => {
        const newPropertyValueStr = match.propKeyValue.replace(
          match.value,
          `"${match.value}"`
        );

        jsonString = jsonString.replace(
          match.propKeyValue,
          newPropertyValueStr
        );
      });
    });
  } else {
    findMaches(jsonString, propKey).forEach((match) => {
      const newPropertyValueStr = match.propKeyValue.replace(
        match.value,
        `"${match.value}"`
      );

      jsonString = jsonString.replace(match.propKeyValue, newPropertyValueStr);
    });
  }

  return jsonString;
}

function findMaches(
  jsonString: string,
  prop: string
): { propKeyValue: string; value: string }[] {
  const regex = new RegExp(`\"${prop}\"\:\\s?(\\d*)\\s?`, 'gm');
  let m;

  const maches: { propKeyValue: string; value: string }[] = [];

  while ((m = regex.exec(jsonString)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (m[0] && m[1]) {
      maches.push({
        propKeyValue: m[0],
        value: m[1],
      });
    }
  }

  return maches;
}
