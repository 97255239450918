import { SosApiService } from './../../common/services/api/api.service';
import { Injectable } from '@angular/core';
import { CLUB_API_PATHS, CLUB_STATE_TOKEN } from './club.const';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { ClubActions } from './club.actions';
import { ModulesSelectors } from '../../common';
import { tap } from 'rxjs/operators';

export class ClubStateModel {
    member_title: string = '';
    not_member_title: string = '';
    benefits: {
        not_member: any[];
        member: any[];
    } = {
        not_member: [],
        member: [],
    };
    cancel_reasons: any = null;
}

@State<ClubStateModel>({
    name: CLUB_STATE_TOKEN,
    defaults: new ClubStateModel(),
})
@Injectable()
export class ClubState {
    constructor(private store: Store, private sosApiService: SosApiService) {}

    @Action(ClubActions.FetchBenefits)
    getBenefits(
        { dispatch, getState, patchState }: StateContext<ClubStateModel>,
        payload: ClubActions.FetchBenefits
    ) {
        const clubBenefitsComponent = this.store.selectSnapshot(
            ModulesSelectors.getComponentFromModule('membership', 'club_benefits')
        );

        patchState({
            member_title: clubBenefitsComponent ? clubBenefitsComponent.member_title : '',
            not_member_title: clubBenefitsComponent ? clubBenefitsComponent.not_member_title : '',
            benefits: clubBenefitsComponent ? clubBenefitsComponent.benefits : {
                not_member: [],
                member: [],
            },
        });
    }

    @Action(ClubActions.FetchCancelReasons)
    getCancelReasons({ patchState }: StateContext<ClubStateModel>) {
        return this.sosApiService.get(CLUB_API_PATHS.cancel_reasons).pipe(
            tap((res) => {
                patchState({
                    cancel_reasons: res.data,
                });
            })
        );
    }

    @Action(ClubActions.MembershipCancel)
    ClubCancel(
        { patchState }: StateContext<ClubStateModel>,
        payload: ClubActions.MembershipCancel
    ) {
        return this.sosApiService
            .post(CLUB_API_PATHS.cancel_membership, payload.cancel)
            .pipe(tap((res) => {}));
    }

    @Action(ClubActions.ResetCancelReasons)
    resetCancelReasons({ patchState }: StateContext<ClubStateModel>) {
        patchState({
            cancel_reasons: null,
        });
    }

    @Action(ClubActions.ClaimDeal)
    claimDeal(
        { patchState }: StateContext<ClubStateModel>,
        payload: ClubActions.ClaimDeal
    ) {
        return this.sosApiService
            .post(CLUB_API_PATHS.claim_deal, {
                department_id: payload.departmentId,
                priority_id: payload.priorityId,
                description: 'some text',
            })
            .pipe(tap((res) => {}));
    }
}
