<div class="modal-inner-content">
    <div class="icon-wrap" *ngIf="modalData?.theme" [ngSwitch]="modalData?.theme">
        <span class="icon-holder theme-{{modalData.theme}}" *ngSwitchCase="'error'">
            <serviceos-ng-system-icon [icon]="'close-circle'"></serviceos-ng-system-icon>
        </span>
        <span class="icon-holder theme-{{modalData.theme}}" *ngSwitchCase="'warning'">
            <serviceos-ng-system-icon [icon]="'important-circle'"></serviceos-ng-system-icon>
        </span>
        <span class="icon-holder theme-{{modalData.theme}}" *ngSwitchCase="'success'">
            <serviceos-ng-system-icon [icon]="'check-cirlce'"></serviceos-ng-system-icon>
        </span>
        <span class="icon-holder theme-{{modalData.theme}}" *ngSwitchCase="'info'">
            <serviceos-ng-system-icon [icon]="'info-circle'"></serviceos-ng-system-icon>
        </span>
    </div>
    <span class="title" *ngIf="modalData?.title">{{ modalData.title }}</span>
    <p *ngIf="modalData?.message" [innerHTML]="modalData.message"></p>
</div>

<ul class="action-list" *ngIf="modalData?.buttons && modalData?.buttons.length">
    <li *ngFor="let button of modalData.buttons; let first = first; let last = last">
        <serviceos-ng-button (click)="closeModal(button.type)" [isFullWidth]="true" [type]="first && !last ? 'secondary' : 'primary'">{{ button.title }}</serviceos-ng-button>
    </li>
</ul>