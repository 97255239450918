import { BookingStateModule } from './booking/booking-state.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingsStateModule } from './bookings/bookings-state.module';


@NgModule({
  imports: [
    CommonModule,
    BookingsStateModule,
    BookingStateModule
  ]
})
export class BookingsStoreModule {}
