import { SocialProvider } from './../authentication-types';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { popupCenter, SosCryptoService } from '../../../helpers';
import { ApiRequest, Environment } from '../../../common';
import { BaseSocialAuth } from './base-social-auth';
import { SessionResponseData } from '../authentication-types';
import { TranslateService } from '@ngx-translate/core';

/**
 * Facebook authentication services use in register and login components
 */
@Injectable({
    providedIn: 'root',
})
export class FacebookAuthService extends BaseSocialAuth {
    authPaths: { login: string } = {
        login: '/ext/facebook-login',
    };

    public popupInstance: Window | null = null;

    constructor(cryptoService: SosCryptoService, env: Environment, translate: TranslateService) {
        super(cryptoService, env, translate);
    }

    /**
     * Open new window for communication with Accounts - they make the actual authentication and return us the response from login/register request
     * @param {sting} requestType could be "login" and "register"
     * @return {Promise}
     */
    public beginAuth(socialProvider: SocialProvider) {
        return new Promise<ApiRequest<SessionResponseData>>(
            (resolve: Function, reject: Function) => {
                const key = socialProvider.data.app_id;

                // This need to be change when we have one authorization location for all clients
                const authorizationDomain =
                    window.location.origin + this.authPaths.login;

                const state = this.createState();
                const facebookAuthURL = `https://www.facebook.com/dialog/oauth?client_id=${key}&redirect_uri=${authorizationDomain}&state=${state}&response_type=token&scope=email,public_profile&display=popup`;

                const apiRequest = new ApiRequest({
                    type: 'POST',
                    url: facebookAuthURL,
                    data: null,
                    options: null,
                });

                let authenticationResponse: string | null = null;
                const fbLoginPostMessageHandler = (event) => {
                    if (event && event.data && event.data.c_fb_login_data) {
                        authenticationResponse = event.data.c_fb_login_data;
                        fbAuthPopup?.close();
                    }
                };
                // append listener for event
                window.addEventListener('message', fbLoginPostMessageHandler);

                const centerPopup = popupCenter();
                const fbAuthPopup = window.open(
                    facebookAuthURL,
                    'Booking Form Authorization Modal',
                    'height=710,width=630,left=' +
                        centerPopup.left +
                        ',top=' +
                        centerPopup.top +
                        ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no'
                );

                this.popupInstance = fbAuthPopup;

                const subscription = interval(1000).subscribe(async() => {
                    // Wait to close popup.
                    if (fbAuthPopup && !fbAuthPopup.closed) {
                        return;
                    }

                    if (authenticationResponse == null) {
                        try {
                            authenticationResponse = localStorage.getItem(
                                'c_fb_login_data'
                            );
                            localStorage.removeItem('c_fb_login_data');
                        } catch (e) {}
                    }

                    if (authenticationResponse) {
                        const socialData: any = this.cryptoService.decryptData(
                            authenticationResponse,
                            this.env.cookieKey
                        );

                        apiRequest.setResponse(socialData);
                        if (socialData && !socialData.error) {
                            resolve(apiRequest);
                        } else {
                            reject(apiRequest);
                        }
                    } else {
                        apiRequest.setResponse(
                           await this.unexpectedErrorWhenAuthorize(socialProvider)
                        );
                        reject(apiRequest);
                    }

                    window.removeEventListener(
                        'message',
                        fbLoginPostMessageHandler
                    );
                    subscription.unsubscribe();
                });
            }
        );
    }
}
