export const VALIDATIONS_STATE_TOKEN = 'VALIDATIONS';

export interface ValidationPattern {
    path: string;
    fields: InputField[];
}

export interface InputField {
    name: string;
    regex: string;
    required: boolean;
}