<div class="sos-dropdown-item-wrap" [ngClass]="{ open: showItems }" (clickOutside)="toggleDropDown(false)">
    <div class="sos-input-wrapper">
        <input *ngIf="!mobile" type="text" class="sos-select" name="selected-item" spellcheck="false"
            autocapitalize="off" autocomplete="off" autocorrect="off" readonly="true" (keyup)="keuUp($event)"
            (focus)="toggleDropDown(true)" (blur)="toggleDropDown(false)"
            [value]="selectedItem?.title ? selectedItem?.title : 'Select item'" [ngClass]="{ bordered: showItems }" />

        <!-- <span class="sos-arrow-down"></span> -->

        <select class="sos-select" *ngIf="mobile" [(ngModel)]="selectedItem"
            (ngModelChange)="selectItem(selectedItem, true)" (blur)="toggleDropDown(false)" #test="ngModel">
            <option [ngValue]="'null'">Select item</option>
            <option *ngFor="let item of settings.choice_items" [ngValue]="item">{{ item.title }}</option>
        </select>
    </div>
    <ul *ngIf="!mobile" class="sos-choice-item-holder sos-nice-scroll" #dropdownList>
        <li (mousedown)="selectItem(null, true)" [ngClass]="{ 'sos-selected': selectedItem?.item == null }">Select item
        </li>
        <li *ngFor="let item of settings.choice_items" (mousedown)="selectItem(item, true)"
            [ngClass]="{ 'sos-selected': selectedItem == item }" #dropdownItems>
            {{ item.title }}
        </li>
    </ul>
</div>