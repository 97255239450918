import { LocalizationSelectors } from './localization.selectors';
import { Injectable } from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, mergeMap, take, delay, tap } from 'rxjs/operators';
import { LocalizationActions } from './localization.actions';

export class RouteLocalizationConfig {
    public key: string;
    public path: string;
    public modules: string[];

    constructor(config: {
        key: string;
        path: string;
        modules: string[];
    }) {
        this.key = config.key;
        this.path = config.path;
        this.modules = config.modules;
    }
}

@Injectable({
    providedIn: 'root',
})
export class LocalizationResolver implements Resolve<boolean> {
    constructor(private store: Store) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        if (!route.data.routeLocalizationConfig)
            throw new Error(
                'Missing route configuration: route.data.routeLocalizationConfig'
            );

        if (
            !(
                route.data.routeLocalizationConfig instanceof
                RouteLocalizationConfig
            )
        )
            throw new Error(
                'Invalid route.data.routeLocalizationConfig not instanceof RouteLocalizationConfig'
            );

        return this.store
            .select(
                LocalizationSelectors.isPageLocalizationLoaded(
                    route.data.routeLocalizationConfig.key
                )
            )
            .pipe(
                take(1),
                // mergeMap((isPageLocalizationLoaded) => {
                //     if (isPageLocalizationLoaded) {
                //         return this.store.dispatch(
                //             new LocalizationActions.UpdateTranslation()
                //         );
                //     } else {
                //         let modules: [] = [];

                //         return this.store.dispatch(
                //             new LocalizationActions.LoadPageLocalization(
                //                 route.data.routeLocalizationConfig
                //             )
                //         );
                //     }
                // }),
                delay(10), // added to dont stack pages one after another
                map((res) => {
                    // return true; // return directly true because currently we dont use page localization
                    return !!this.store.selectSnapshot(
                        LocalizationSelectors.isPageLocalizationLoaded(
                            route.data.routeLocalizationConfig
                        )
                    );
                }),
                tap((res) => {
                    // console.warn('LOCALIZATION RESOLVER:', res);
                })
            );
    }
}
