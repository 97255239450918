import { AfterViewInit, Component, OnInit } from '@angular/core';

declare global {
    interface Window {
        GDPR: any;
    }
}

@Component({
    selector: 'serviceos-ng-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit {
    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            window.GDPR && typeof window.GDPR == 'function' && window.GDPR();
        }, 1000);
    }
}
