import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultModalComponent } from './default-modal/default-modal.component';
import { SystemIconModule } from './../shared/base/system-icon/system-icon.module';
import { ButtonModule } from '../shared/base/button/button.module';



@NgModule({
  declarations: [DefaultModalComponent],
  imports: [
    CommonModule,
    SystemIconModule,
    ButtonModule
  ],
  exports: [DefaultModalComponent]
})
export class DefaultModalModule { }
