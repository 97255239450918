import {
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    AfterContentInit,
} from '@angular/core';
import { InputDirective } from '../input.directive';

@Component({
    selector: 'serviceos-ng-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit, AfterContentInit {
    @ContentChild(InputDirective) _input;
    @ContentChild(InputDirective, { static: true }) _inputStatic;
    get input() {
        return this._input || this._inputStatic;
    }

    constructor() {}

    ngOnInit(): void {}

    ngAfterContentInit(): void {}
}
