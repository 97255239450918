import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import {
    catchError,
    finalize,
    map,
    retry,
    switchMap,
    tap,
    timestamp,
} from 'rxjs/operators';
import {
    ApiRequest,
    SosApiService,
} from '../../common/services/api/api.service';
import { SosCoreConfig } from '../../core-config';
import { SosLoaderService } from '../../elements';

import { AuthorizationSelectors } from '../authorization-store/authorization.selectors';
import { AccountActions } from './account.actions';
import { LocalStorageService } from '../../common/services/local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(
        private sosApiService: SosApiService,
        private store: Store,
        private httpClient: HttpClient,
        private sosLoaderService: SosLoaderService,
        private config: SosCoreConfig,
        private translateService: TranslateService,
        private _localStorageService: LocalStorageService

    ) {
        this.store
            .select(AuthorizationSelectors.isAuthorize)
            .subscribe((isAuth) => {
                if (isAuth) {
                    this.store.dispatch(new AccountActions.Fetch());
                } else {
                    this.store.dispatch(new AccountActions.Reset());
                }
            });
    }

    fetch() {
        return this.sosApiService.get('client/user', {
            'paging[limit]': 9999,
            'expand[0]': 'all.all.all',
        });
    }

    updateLanguage(data) {
        return this.sosApiService.post('client/user', data);
    }

    updateName(data) {
        return this.sosApiService.post('client/user', data);
    }

    updatePassword(data) {
        return this.sosApiService.post('client/change_password', data);
    }

    resetPassword(data) {
        return this.sosApiService.post('client/request_reset_password', data);
    }

    updateBirthdate(data) {
        return this.sosApiService.post('client/user', data);
    }

    uploadFile(formData) {
        const headers = this.createHeaders();

        const request = new ApiRequest({
            url: this.config.api_url + 'shared/upload_file',
            type: 'POST',
            data: formData,
            options: { headers },
        });

        return this.httpClient
            .post(this.config.api_url + 'shared/upload_file', formData, {
                headers,
            })
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError((err) => {
                    let response = {
                        error: [
                            {
                                code: 100777,
                                message:
                                    'Oops! Seems like something went wrong on our end! Please accept our apologies and try again.',
                            },
                        ],
                    };

                    // There is error.status == 0.Then request is blocked or something else has happened this is why we use then 100777
                    if (err.status) {
                        response.error[0].code =
                            100000 + err.status;
                    }

                    request.setResponse(response);

                    // return an observable with a user-facing error message
                    return throwError(request);
                }),
                map((response: any) => {
                    request.setResponse(response);

                    if (request.error) {
                        throw request;
                    }

                    return request;
                })
            );
    }

    updateAvatar(data) {
        return this.sosApiService.post('client/avatar', data);
    }

    getAvatar() {
        return this.sosApiService.get('client/user/avatar');
    }

    fetchReferralStats(): Observable<any> {
        return this.sosApiService.get('client/referral_stats').pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for fetching addresses
     * @returns
     */
    public fetchAddresses(): Observable<any> {
        return this.sosApiService.get('client/user/addresses').pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for creating address
     * @param address address object
     * @returns
     */
    public createAddress(address: {
        address_line_one: string;
        address_line_two: string;
        postcode: string;
    }): Observable<any> {
        const queryParams: any = {
            return: 'object',
            'expand[0]': 'all.all.all.all',
        };
        return this.sosApiService
            .post('client/addresses', address, queryParams)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for deleting address
     * @param address address object
     * @returns
     */
    public deleteAddress(address: { id: number }): Observable<any> {
        return this.sosApiService.delete('client/user/addresses', address).pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for creating address
     * @param address address object
     * @returns
     */
    public markAddressAsDefault(address: {
        id: number;
        address_line_one: string;
        address_line_two: string;
        postcode: string;
        default: boolean;
    }): Observable<any> {
        const queryParams: any = {
            return: 'object',
            'expand[0]': 'all.all.all.all',
        };

        return this.sosApiService
            .post('client/user/addresses/' + address?.id, address, queryParams)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for fetching phones
     * @returns
     */
    public fetchPhones(): Observable<any> {
        return this.sosApiService.get('client/user/phones').pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for creating phone
     * @param phone phone object
     * @returns
     */
    public createPhone(phone: { value: string }): Observable<any> {
        const queryParams: any = {
            return: 'object',
            'expand[0]': 'all.all.all.all',
        };

        return this.sosApiService
            .post('client/user/phones', phone, queryParams)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for deleting phone
     * @param phone phone object
     * @returns
     */
    public deletePhone(phone: { id: number }): Observable<any> {
        return this.sosApiService.delete('client/user/phones', phone).pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for marking phone as default
     * @param phone phone object
     * @returns
     */
    public markPhoneAsDefault(phone: {
        id: number;
        value: string;
        default: boolean;
    }): Observable<any> {
        const queryParams: any = {
            return: 'object',
            'expand[0]': 'all.all.all.all',
        };

        return this.sosApiService
            .post('client/user/phones', phone, queryParams)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for fetching preferences
     * @returns
     */
    public fetchPreferences(): Observable<any> {
        return this.sosApiService.get('client/user/preferences').pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for updating user preferences
     * @param preferences preferences object
     * @returns
     */
    public updatePreferences(preferences: {
        allow_mk_sms?: boolean;
        allow_mk_email?: boolean;
        allow_mk_call?: boolean;
        allow_mk_push_notification?: boolean;
        allow_mk_letter?: boolean;
    }): Observable<any> {
        const queryParams: any = {
            'expand[0]': 'all.all.all.all',
        };

        return this.sosApiService
            .post('client/user/preferences', preferences, queryParams)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for requesting account delete
     * @param data comment and password
     * @returns
     */
    public requestDeleteAccount(data: {
        comment: string;
        password: string;
    }): Observable<any> {
        return this.sosApiService
            .post('client/request_delete_account', data)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for fetching paymethods
     * @returns
     */
    public fetchPaymethods(): Observable<any> {
        return this.sosApiService.get('client/paymethods').pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for fetching paymethods
     * @returns
     */
    public createPaymethod(data: {
        payment_method_id: number;
        // paymethod: {
        setup: {
            type: string;
            payload: {
                token: string;
                device_data?: any;
            };
        };
        // };
    }): Observable<any> {
        const queryParams: any = {
            return: 'object',
            'expand[0]': 'all.all.all.all',
        };

        return this.sosApiService
            .post('client/user/paymethods', data, queryParams)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for deleting paymethod
     * @param paymethod paymethod object
     * @returns
     */
    public deletePaymethod(paymethod: { id: number }): Observable<any> {
        return this.sosApiService
            .delete('client/user/paymethods', paymethod)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for marking paymethod as default
     * @param paymethod paymethod object
     * @returns
     */
    public markPaymethodAsDefault(paymethod: {
        id: number;
        sort: number;
        default: boolean;
        description: string;
        label: string;
        brand: string;
        payment_method_id: number;
    }): Observable<any> {
        const queryParams: any = {
            return: 'object',
            'expand[0]': 'all.all.all.all',
        };

        return this.sosApiService
            .post('client/user/paymethods', paymethod, queryParams)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    /**
     * Method for fetching payment methods
     * @returns
     */
    public fetchPaymentMethods(): Observable<any> {
        return this.sosApiService.get('client/user/payment_methods').pipe(
            map((res) => {
                return res.data;
            })
        );
    }

    /**
     * Method for retrieving user paymethod setup
     * @returns
     */
    public getPaymethodSetup(requestData: {
        payment_method_id: number;
    }): Observable<any> {
        return this.sosApiService
            .post('client/user/paymethod_setup', requestData)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    private createHeaders() {
        let headers = new HttpHeaders();

        let  currentLanguage = '';

        if (this._localStorageService.getItem('app_default_language') && this._localStorageService.getItem('app_default_language') !== 'undefined') {
            currentLanguage = this._localStorageService.getItem('app_default_language');
        }

        headers = headers.append('X-Application', this.config.key);
        headers = headers.append('X-Profile', this.config.profile_id);

        if (currentLanguage) {
            headers = headers.append('X-Language', currentLanguage);
        }

        return headers;
    }
}
