export function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export function deepMerge(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else output[key] = deepMerge(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

export const flatten = (obj, roots: string[] = [], sep = '.') =>
    Object.keys(obj).reduce(
        (memo, prop) =>
            Object.assign(
                {},
                memo,
                Object.prototype.toString.call(obj[prop]) === '[object Object]'
                    ? flatten(obj[prop], roots.concat([prop]), sep)
                    : { [roots.concat([prop]).join(sep)]: obj[prop] }
            ),
        {}
    );

/**
 * Get nested object property from path string
 * @param from Object
 * @param selector Key selector
 * @returns
 */
export const get = (from, selector) => {
    return selector
        .replace(/\[([^\[\]]*)\]/g, '.$1.')
        .split('.')
        .filter((t) => t !== '')
        .reduce((prev, cur) => prev && prev[cur], from);
};
