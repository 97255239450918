import { ClubState } from './club.state';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';

@NgModule({
    declarations: [],
    imports: [CommonModule, NgxsModule.forFeature([ClubState])],
})
export class ClubStoreModule {}
