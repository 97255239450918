import { Component, OnInit } from '@angular/core';
import { ModalOverlayRef } from '../modals/modal-overlay-ref';

@Component({
    selector: 'serviceos-web-carousel-modal',
    templateUrl: './carousel-modal.component.html',
    styleUrls: ['./carousel-modal.component.scss'],
})
export class CarouselModalComponent implements OnInit {

	public modalData;

    constructor(private modalRef: ModalOverlayRef) {}

    ngOnInit(): void {
		this.modalData = this.modalRef.configData;
	}
}
