import { ClubState, ClubStateModel } from './club.state';
import { Selector } from '@ngxs/store';
import { initialFormat } from '../../helpers';
import { AccountSelectors } from '../account-store/account.selectors';

export class ClubSelectors {
    
    
    @Selector([ClubState, AccountSelectors.membership])
    static getBenefits(state: ClubStateModel, membership) {
        if(membership) {
            return {
                title: state.member_title,
                benefits: state.benefits.member
            }
        } else {
            return {
                title: state.not_member_title,
                benefits: state.benefits.not_member
            }
        }
    }

    @Selector([ClubState])
    static cancelReasons(state: ClubStateModel) {
        return state?.cancel_reasons || null;
    }
}
