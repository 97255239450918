import { BookingsState, BookingsStateModel } from './bookings.state';
import { Selector, createSelector } from '@ngxs/store';

export class BookingsSelectors {
    @Selector([BookingsState])
    static getUpcoming(state: BookingsStateModel) {
        return state?.bookings;
    }

    @Selector([BookingsState])
    static getPast(state: BookingsStateModel) {
        return state?.pastBookings;
    }

    @Selector([BookingsState])
    static getUnratedPastBookings(state: BookingsStateModel) {
        return state?.pastBookings?.filter(
                booking => booking.rate === null
        );
    }

    @Selector([BookingsState])
    static getPastLength(state: BookingsStateModel) {
        return state?.pastBookingsLength;
    }

    static getById(bookingId: string | number) {
        return createSelector([BookingsState], (state: BookingsStateModel) => {
            return [...state?.bookings, ...state?.pastBookings].find(
                (booking) => {
                    return (
                        booking.id == bookingId || booking?.objectId == bookingId
                    );
                }
            );
        });
    }
}
