<div class="local-loader-holder" *ngIf="showLoader">

    <div class="loader-box">
        <div class="centered">
            <div class="loader-item">
                <div class="inner-loader obf-loading"></div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
        </div>
    </div>
</div>

