import { LocalLoaderModule } from './../local-loader/local-loader.module';
import { SystemIconModule } from './../shared/base/system-icon/system-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProTrackingComponent } from './pro-tracking/pro-tracking.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ButtonModule } from './../shared/base/button/button.module';
import { LinkModule } from './../shared/base/link/link.module';

@NgModule({
    declarations: [ProTrackingComponent],
    imports: [
        CommonModule,
        NgbProgressbarModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NgxSkeletonLoaderModule,
        ButtonModule,
        SystemIconModule,
        LinkModule,
        LocalLoaderModule,
    ],
    exports: [ProTrackingComponent],
})
export class ProTrackingModule {}
