import { ApiRequest } from '../../services/api/api.service';

export namespace ErrorActions {
    export class Api {
        static readonly type = '[ERROR] Api error';
        constructor(public request: ApiRequest) {}
    }

    export class Javascript {
        static readonly type = '[ERROR] Javascript';
        constructor(public code, public error) {}
    }

    export class Localization {
        static readonly type = '[ERROR] Localization error';
        constructor(public error) {}
    }

    export class Custom {
        static readonly type = '[ERROR] Custom error';
        constructor(public data: any) {}
    }
}
