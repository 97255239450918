export namespace BookingActions {
    export class Refresh {
        static readonly type = `[BOOKING] Refresh Booking`;
        constructor(public bookingId: string, public bookingScreen: string) {}
    }
    export class GetSingle {
        static readonly type = `[BOOKING] Get Single`;
        constructor(public bookingId: string, public isHistory: boolean) {}
    }

    export class GetSingleV2 {
        static readonly type = `[BOOKING] Get Single v2`;
        constructor(public bookingId: string, public history: boolean = false
        ) {}
    }

    export class GetRegularSession {
        static readonly type = `[BOOKING] Get Regular Session`;
        constructor(public bookingId: string) {}
    }

    export class GetRegularSessions {
        static readonly type = `[BOOKING] Get Regular Sessions`;
        constructor(public bookingId: string) {}
    }

    export class GetMoreRegularSessions {
        static readonly type = `[BOOKING] Get More Regular Sessions`;
        constructor(public bookingId: string) {}
    }

    export class GetProTrackingInfo {
        static readonly type = `[BOOKING] Get Pro Tracking Info`;
        constructor(public bookingId: string) {}
    }

    export class AddBookingNote {
        static readonly type = `[BOOKING] Add Booking Note`;
        constructor(public note: string, public screen: string) {}
    }

    export class Reset {
        static readonly type = `[BOOKING] Reset`;
    }
}
