import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesStateModule } from './services/services-state.module';
import { UnitsStateModule } from './units/units-state.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ServicesStateModule, UnitsStateModule],
  exports: [ServicesStateModule, UnitsStateModule],
})
export class BookingTransactionStoreModule {}
