<label [for]="inputId" class="{{ documentDirection }}">
    <input
        (change)="_onChangeEvent($event)"
        #input
        type="checkbox"
        [attr.name]="name"
        [id]="inputId"
        [disabled]="disabled"
        [checked]="checked"
        hidden="true"
        (click)="_onInputClick($event)"
    />

    <div class="toggle" [ngClass]="{ checked: checked , 'disabled': disabled}">
        <div class="toggle-thumb">
            <div class="toggle-curcle"> </div>
        </div>

        <div class="toggle-label" *ngIf="!hideLabel">
            {{ label }}
        </div>
    </div>
</label>
