import { LocalLoaderModule } from './../local-loader/local-loader.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitComponent } from './unit/unit.component';
import { ProComponent } from './pro/pro.component';
import { TeamComponent } from './team/team.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SystemIconModule } from './../shared/base/system-icon/system-icon.module';
import { ButtonModule } from '../shared/base/button/button.module';
import { LinkModule } from '../shared/base/link/link.module';

// TODO refactor the components to more small parts to reduce scss and be more reusable.
@NgModule({
  declarations: [
    UnitComponent,
    ProComponent,
    TeamComponent
  ],
  imports: [
    CommonModule,
    SystemIconModule,
    ButtonModule,
    LinkModule,
    LocalLoaderModule,
    NgbNavModule
  ],
  exports: [
    UnitComponent
  ]
})
export class UnitModule { }
