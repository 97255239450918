export namespace ValidationsActions {
    export class Initialize {
        static readonly type = `[VALIDATIONS] Initialize`;
        constructor() {}
    }

    export class GetAll {
        static readonly type = '[VALIDATIONS] GetAll';
        constructor() {
        }
    }
}