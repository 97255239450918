import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Choice, ChoiceItem, ChoiceItemValueChange } from '../../../../../typings';
import moment from 'moment-mini';

// TODO Refactor split the logic to needed types.
@Component({
  selector: 'serviceos-ng-text-field-item',
  templateUrl: './text-field-item.component.html',
  styleUrls: ['./text-field-item.component.scss'],
})
export class TextFieldItemComponent implements OnInit {
  @Output()
  public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

  @Input()
  public settings!: ChoiceItem;

  @Input()
  public parentInstance!: ChoiceItem | Choice;

  @Input()
  public serviceCustomize;

  @Output()
  public onFocusSubscribe = new EventEmitter();

  @Output()
  public outFocusSubscribe = new EventEmitter();

  @ViewChild('textField')
  public textField!: ElementRef;

  @Input()
  public type: string | undefined; // default.

  // Date picker
  public showDataPicker = false;
  public datePickerMinDate: any = null;

  public startDate = { year: 2019, month: 2 };

  public dropdownMaxHeight = 250;

  public searchControl = new FormControl();

  public expandedTextarea = false;

  constructor() {}

  public ngOnInit() {
    this.initialize();
  }

  public ngOnDestroy() {}

  /**
   * Component initializer
   */
  private initialize(): void {
    if (this.settings.value === 0) {
      this.settings.value = '';
    }

    if (!this.type) {
      this.type = this.checkTheTypeOfInputField();
    }

    // Datepicker
    if (this.type === 'date-picker') {
      const currentDate = moment();

      let daysOffSet = 0;

      if (this.settings.customize && this.settings.customize.days_offset) {
        try {
          daysOffSet += parseInt(this.settings.customize.days_offset, 10);
        } catch (e) {}
      }

      currentDate.add(daysOffSet, 'days');
      // Set min date for datetime picker
      this.datePickerMinDate = {
        year: currentDate.year(),
        month: currentDate.month() + 1, // Months are indexed from 0
        day: currentDate.date(),
      };
    }
  }

  /**
   * Value getter
   */
  public getValue() {
    return this.textField.nativeElement.value;
  }

  /**
   * Checks if there is a value entered
   * @return {boolean}
   */
  public isValidField() {
    if (!this.textField.nativeElement.value) {
      return false;
    }

    return true;
  }

  /**
   * Element focus handler
   */
  public autoFocus(): void {
    this.textField.nativeElement.focus();
  }

  /**
   * Select date from datepicker
   * @param event
   */
  public selectedDate(event: any): void {
    // set the new value for datepicker
    this.settings.value =
      `${event.day < 10 ? '0' : ''}` +
      event.day +
      `/${event.month < 10 ? '0' : ''}` +
      event.month;
    this.showDataPicker = false;

    this.changeChoiceItemValue.emit({
      item: this.settings,
      userInteraction: true,
    });
  }

  /**
   * showDataPicker flag setter
   * @param event
   */
  public hideDatePicker(event: any): void {
    this.showDataPicker = !this.showDataPicker;
  }

  /**
   * Handler for change in value of input
   * @param event
   */
  public onChangeValue(event: any): void {
    this.settings.value = event;

    this.changeChoiceItemValue.emit({
      item: this.settings,
      userInteraction: true,
    });
  }

  /**
   * Check type of the input
   */
  private checkTheTypeOfInputField(): string {
    let type: string;

    switch (this.settings.type) {
      case 'text_field':
        type = 'textarea';

        if (
          this.settings.customize &&
          this.settings.customize.obfTextAreaToInput === '1'
        ) {
          type = 'text';
        }

        if (
          (this.settings.customize &&
            this.settings.customize.datePicker === '1') ||
          (this.settings.customize &&
            this.settings.customize.display_type === 'date')
        ) {
          type = 'date-picker';
        }

        break;
      case 'decimal_text':
        type = 'decimal';
        break;
      default:
        type = 'text';
        break;
    }
    return type;
  }

  /**
   * Element focus handler
   * @param textField
   * @param dp
   */
  public focusIn(textField?: Element, dp?): void {
    this.showDataPicker = true;

    if (
      this.type === 'date-picker' &&
      this.settings.value &&
      this.settings.value.length === 5
    ) {
      const selectedMonth = +this.settings.value.slice(3);
      if (selectedMonth < this.datePickerMinDate.month) {
        this.startDate = { year: moment().year() + 1, month: selectedMonth };
      } else {
        this.startDate = { year: moment().year(), month: selectedMonth };
      }
    }

    this.onFocusSubscribe.emit({
      element: textField,
      event: 'focus',
    });

    if (textField && this.type === 'textarea') {
      textField.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * Focus out handler
   * @param textField
   */
  public focusOut(textField: any): void {
    this.showDataPicker = false;

    this.outFocusSubscribe.emit({
      element: textField,
      event: 'focusOut',
    });
  }
}
