import { LogoModule } from './../logo/logo.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SosNavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { PageContentComponent } from './page-content/page-content.component';
import { FrameComponent } from './frame/frame.component';
import { SystemIconModule } from '../shared/base/system-icon/system-icon.module';
import { LayoutModule } from '@angular/cdk/layout';
import { FooterComponent } from './footer/footer.component';
@NgModule({
    declarations: [
        SosNavigationComponent,
        HeaderComponent,
        PageContentComponent,
        FrameComponent,
        FooterComponent,
        
    ],
    imports: [CommonModule, SystemIconModule, LayoutModule, LogoModule],
    exports: [
        SosNavigationComponent,
        HeaderComponent,
        PageContentComponent,
        FrameComponent,
        FooterComponent,
    ],
})
export class SosFrameModule {}
