import { TranslateService } from '@ngx-translate/core';
import { formattedError } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { isInFuture, isInPast } from '../../helpers';
import { take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ModulesSelectors } from '../../common';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    constructor(private translateService: TranslateService, private store: Store) {}

    // ? This will be remove when new booking endpoint is ready.
    public extendBookingData(booking, isHistory?) {

        // return same booking if its undefined
        if (!booking) return booking;

        let appTexts = {
            "booking_action_reschedule": "Reschedule",
            "booking_action_edit_booking": "Edit service",
            "booking_action_edit_plan": "Manage plan",
            "booking_action_edit_session": "Edit session",
            "booking_action_cancel": "Cancel",
            "booking_action_cancel_session": "Skip session",
            "booking_action_rate": "Rate",
            "booking_action_rebook": "Rebook",
            "booking_action_view_all": "View all",
            "booking_action_complain": "Log a complaint"
        };

        const bookingsModuleTexts: any = this.store.selectSnapshot(ModulesSelectors.getModuleByName('bookings')).texts;

        if (bookingsModuleTexts && bookingsModuleTexts.card_component) {
            const cardComponent: any = bookingsModuleTexts.card_component; 

            Object.keys(appTexts).forEach((actionKeyword: string) => {
                // overwrite keyword with the one from module texts
                if (cardComponent[actionKeyword]) appTexts[actionKeyword] = cardComponent[actionKeyword];
            });
        }

        // this.translateService
        //     .get('app')
        //     .pipe(take(1))
        //     .subscribe((texts: any) => {
        //         appTexts = texts;
        //     });
        
        // Img will not be found in dev mode. Because th path is differed from live build.
        booking.service_image_url =
            booking.serviceImage || booking.service?.inactive_booking_image_url || this.store.selectSnapshot(ModulesSelectors.getImageFromModule('bookings', 'default_service_image_url'));
        
        // static backup
        if (!booking.service_image_url) booking.service_image_url = 'https://files.dxr.cloud/NongnVSDWE9NSNgeXt7HuiCrb2c39CnGzyX8bvuWRlsJ5cth3KEclXChxJJL';

        // Set past booking flag
        let isPastBooking: boolean = false;

        if (isHistory !== undefined) {
            isPastBooking = isHistory;
        } else {
            // when get single booking set flag isPastBooking depends on booking date/appointment
            isPastBooking = isInPast(booking.bookDate) && booking.type !== 'regular';
        }

        const fsScreen = isPastBooking ? 'history' : 'bookings';

        booking.past_booking = isPastBooking;

        // Set actions flags (v1 + v2)
        const actions: any[] = [],
            bookingId = booking?.objectId || booking?.id,
            serviceId = booking.serviceId || booking.service?.id,
            canRescheduleUntil =
                booking.canRescheduleUntil || booking.can_reschedule_until,
            canEditUntil =
                booking.canRescheduleUntil || booking.can_reschedule_until,
            canCancelUntil = booking.canCancelUntil || booking.can_cancel_until,
            canRebookUntil = booking.canRebookUntil || booking.can_rebook_until,
            canRateCondition: boolean =
                isPastBooking && (booking.rated == 0 || booking.rate === null),
            canComplainUntil = booking.canComplainUntil || booking.can_complain_until;

        // Set booking contact box flag
        booking.show_contact_box = !!(
            canRescheduleUntil &&
            canCancelUntil &&
            isInPast(canRescheduleUntil) &&
            isInPast(canCancelUntil) &&
            !isPastBooking
        );

        // RATE BOOKING ACTION
        if (canRateCondition) {
            actions.push({
                endpoint: `/bookings/${bookingId}/rate`,
                type: 'rate',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=rate&fs_element_id=' +
                    bookingId,
                title: appTexts.booking_action_rate,
            });
        }

        // EDIT REGULAR BOOKING PLAN ACTION
        const canEditRegularPlanCondition: boolean = booking.type === 'regular';

        if (canEditRegularPlanCondition) {
            actions.push({
                endpoint: '/edit_requests',
                type: 'edit_regular_plan',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=edit&fs_element_id=' +
                    bookingId,
                title: appTexts.booking_action_edit_plan,
                active_from: booking.createdAt,
                active_to: canRescheduleUntil,
            });

            actions.push({
                endpoint: '',
                type: 'navigate',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=navigate&fs_element_id=' +
                    bookingId,
                title: appTexts.booking_action_view_all,
                active_from: booking.createdAt,
                active_to: canRescheduleUntil,
            });
        }

        // EDIT BOOKING/SESSION ACTION
        const canEditCondition: boolean =
            canEditUntil && isInFuture(canEditUntil) && !isPastBooking;

        if (
            (booking.type === 'session' || booking.type === 'booking') &&
            canEditCondition
        ) {
            actions.push({
                endpoint: '/edit_requests',
                type: 'edit',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=edit&fs_element_id=' +
                    bookingId,
                title:
                    booking.type === 'session'
                        ? appTexts.booking_action_edit_session
                        : appTexts.booking_action_edit_booking,
                active_from: booking.createdAt,
                active_to: canEditUntil,
            });
        }

        // RESCHEDULE BOOKING ACTION
        const canRescheduleCondition: boolean =
            !!canRescheduleUntil &&
            isInFuture(canRescheduleUntil) &&
            !isPastBooking;

        if (canRescheduleCondition) {
            actions.push({
                endpoint: '',
                type: 'reschedule',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=reschedule&fs_element_id=' +
                    bookingId,
                title: appTexts.booking_action_reschedule,
                active_until: canRescheduleUntil,
            });
        }

        // CANCEL BOOKING ACTION
        const canCancelCondition: boolean =
            !!canCancelUntil &&
            isInFuture(canCancelUntil) &&
            !isPastBooking &&
            booking.type !== 'regular';

        if (canCancelCondition) {
            actions.push({
                endpoint: '/bookings/' + bookingId + '/cancel',
                type: booking.type !== 'session' ? 'cancel' : 'skip_session',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=cancel&fs_element_id=' +
                    bookingId +
                    (booking.referenceNumber
                        ? '&fs_book_ref_id=' + booking.referenceNumber
                        : ''),
                title:
                    booking.type !== 'session'
                        ? appTexts.booking_action_cancel
                        : appTexts.booking_action_cancel_session,
                active_until: canCancelUntil,
            });
        }

        // REBOOK BOOKIN ACTION
        const canRebookCondition: boolean =
            !!canRebookUntil && isInFuture(canRebookUntil) && isPastBooking;

        if (canRebookCondition) {
            actions.push({
                endpoint: '',
                type: 'renew',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=renew&fs_element_id=' +
                    bookingId +
                    '&fs_service_id=' +
                    serviceId,
                title: appTexts.booking_action_rebook,
                active_until: canRebookUntil,
            });
        }

        // REBOOK BOOKIN ACTION
        const canComplainCondition: boolean =
            !!canComplainUntil && isInFuture(canComplainUntil) && isPastBooking;

        if (canComplainCondition) {
            actions.push({
                endpoint: `/bookings/${bookingId}/rate`,
                type: 'complain',
                deep_link:
                    'fs_screen=' +
                    fsScreen +
                    '&fs_action=complain&fs_element_id=' +
                    bookingId,
                title: appTexts.booking_action_complain,
            });
        }

        booking.actions = actions;

        return booking;
    }
}
