import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'serviceos-ng-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    @Input()
    type: 'error' | 'warning' | 'info' | 'success' = 'info';

    constructor() {}

    ngOnInit(): void {}
}
