import { MissingTranslationHandlerParams } from '@ngx-translate/core';
import { RouteLocalizationConfig } from './localization.resolver';

export namespace LocalizationActions {
    export class AddSupportedLanguages {
        static readonly type = '[LOCALIZATION] Add Supported Languages';
        constructor(
            public languages: { code: string; icon_url: string; id: number; selected: boolean; sort: number; title: string; texts: any; }[]
        ) {}
    }

    export class ChangeLanguage {
        static readonly type = '[LOCALIZATION] Change Language';
        constructor(public language: string) {}
    }

    export class LoadPageLocalization {
        static readonly type = '[LOCALIZATION] Fetch Module Localization';
        constructor(public routeConfig: RouteLocalizationConfig) {}
    }

    export class UpdateTranslation {
        static readonly type = '[LOCALIZATION] Update Translation';
    }

    export class SetShortcodes {
        static readonly type = '[LOCALIZATION] Set Variables';
        /**
         * Will save the shortcodes.
         * @param shortcodes pass the shortcodes flatt or nested in objects
         */
        constructor(public shortcodes: { [key: string]: any }) {}
    }

    export class AddModulesTexts {
        static readonly type = '[LOCALIZATION] Add Modules Texts';
        public modules: { [key: string]: any };
        constructor(modules: { [key: string]: any }) {
            this.modules = Object.keys(modules).reduce((obj, key) => {
                obj[key] = {};
                obj[key].texts = modules[key].texts;
                return obj;
            }, {});
        }
    }
}
