import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'serviceos-ng-collapse-view',
  templateUrl: './collapse-view.component.html',
  styleUrls: ['./collapse-view.component.scss']
})
export class CollapseViewComponent implements OnInit {

  @Input()
  title: string = '';
  
  @Input()
  collapseToggleTitle: string = ''

  @Input()
  expandToggleTitle: string = ''

  public isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

}
