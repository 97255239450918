import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationState } from './authorization.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([AuthorizationState])
  ],
  providers: [
    CookieService
  ]
})
export class SosAuthorizationStoreModule { }
