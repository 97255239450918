import { CHOICE_ITEMS_TYPES } from '../../../constants';
import { Injectable } from '@angular/core';

@Injectable()
export class ChoiceValidationService {
  private validationPatterns;
  // Custom patterns if dont exist in validation endpoint
  private customPatterns = {
    credit_cards: [
      {
        type: 'amex',
        pattern: /^3[47]/,
        valid_length: [15],
        beautyType: 'American Express',
      },
      {
        type: 'visa_electron',
        pattern: /^(4026|417500|4508|4844|491(3|7))/,
        valid_length: [16],
        beautyType: 'Visa Electron',
      },
      {
        type: 'visa',
        pattern: /^4/,
        valid_length: [16],
        beautyType: 'Visa',
      },
      {
        type: 'mastercard',
        pattern: /^5[1-5]/,
        valid_length: [16],
        beautyType: 'Master Card',
      },
      {
        type: 'maestro',
        pattern: /^(5018|5020|5038|6304|6759|676[1-3])/,
        valid_length: [12, 13, 14, 15, 16, 17, 18, 19],
        beautyType: 'Maestro',
      },
    ],

    cvvCode: {
      pattern: /^[0-9]{3,4}$/,
    },

    nameOnCard: {
      pattern: /^([^!{}()_,=@#$%^&*]){2,40}$/,
    },

    expiryDate: {
      pattern: /^([0-9]{2})\/([0-9]{2}|[0-9]{4})$/,
    },
  };

  constructor() {}

  public setValidationPatterns(validationPatterns) {
    this.validationPatterns = validationPatterns;
  }

  public validateChoice(choice) {
    let validChoice = true;

    if (
      !choice.choice_items ||
      (choice.choice_items && choice.choice_items.length === 0)
    ) {
      return validChoice;
    }

    validChoice = this.validateChoiceItems(choice);

    return validChoice;
  }

  public validate(key, value) {
    let validation = true;

    switch (key) {
      case 'fname':
        validation = this.getPattern('register', 'first_name').test(value);
        break;
      case 'lname':
        validation = this.getPattern('register', 'last_name').test(value);
        break;
      case 'addressLine1':
        validation = this.getPattern('addresses', 'address_line_one').test(
          value
        );
        break;
      case 'addressLine2':
        if (value.indexOf('<') >= 0 || value.indexOf('>') >= 0) {
          validation = false;
        }
        break;
      case 'descriptionText':
        if (value.indexOf('<') >= 0 || value.indexOf('>') >= 0) {
          validation = false;
        }
        break;
      case 'email':
        validation = this.getPattern('register', 'email').test(value);
        break;
      case 'phone':
        validation = this.getPattern('phones', 'value').test(value);
        break;
      case 'password':
        validation = this.getPattern('register', 'password').test(value);
        break;
      case 'postcode':
        validation = this.getPattern('addresses', 'postcode').test(value);
        break;
      case 'cardNumber':
        // TODO
        break;
      case 'cvvCode':
        validation = this.customPatterns.cvvCode.pattern.test(value);
        break;
      case 'nameOnCard':
        validation = this.customPatterns.nameOnCard.pattern.test(value);
        break;
      case 'expiryDate':
        validation = this.customPatterns.expiryDate.pattern.test(value);
        break;
      case 'termsAndConditions':
        validation = value;
        break;
      case 'birthDate':
        validation = this.getPattern('register', 'birth_date').test(value);
        break;
    }

    return validation;
  }

  /**
   * Get regex pattern
   * @param searchPath
   * @param searchPattern
   * @returns {{}}
   */
  public getPattern(searchPath, searchPattern) {
    // debugger;
    let regex = '',
      newPattern = '',
      flags = '';

    // tslint:disable-next-line: no-shadowed-variable
    const pattern = this.validationPatterns
      .find((group) => group.path === searchPath)
      .fields.find((pattern) => pattern.name === searchPattern);

    regex = pattern.regex;

    flags = regex.replace(/.*\/([gimy]*)$/, '$1');

    if (flags) {
      newPattern = regex.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
    } else {
      newPattern = regex.replace(new RegExp('^/(.*?)/$'), '$1');
    }

    return new RegExp(newPattern, flags);
  }

  // TODO This need to be simplify !!!
  private validateChoiceItems(choice) {
    let choiceIsValid = false;
    let validationChildren: any[] = [];

    if (choice.required) {
      choice.choice_items.map((choiceItem) => {
        let selectedChild = false;
        let validChild = false;

        // * VALIDATOR FOR ADDRESS
        if (choiceItem.type === 'address') {
          selectedChild = true;
          validChild =
            choiceItem.value &&
            choiceItem.value.postcode &&
            choiceItem.value.address_line_one &&
            this.validate(
              'postcode',
              choiceItem.value.postcode.replace(/\s/g, '')
            ) &&
            choiceItem.value.address_line_one.replace(/\s/g, '') !== '';
        }

        if (choiceItem.type === CHOICE_ITEMS_TYPES.ContactDetails) {
          selectedChild = true;
          validChild =
            choiceItem.value &&
            choiceItem.value.first_name &&
            choiceItem.value.last_name &&
            choiceItem.value.email &&
            choiceItem.value.phones &&
            Array.isArray(choiceItem.value.phones) &&
            choiceItem.value.phones.length
              ? true
              : false;
        }

        // * VALIDATOR FOR ATTACHMENT
        if (
          choiceItem.type === CHOICE_ITEMS_TYPES.Attachment ||
          choiceItem.type === CHOICE_ITEMS_TYPES.PhotoAttachment
        ) {
          selectedChild = true;
          validChild = false;
          if (choiceItem.value.length) {
            validChild = true;

            // tslint:disable-next-line:no-shadowed-variable
            choiceItem.value.map((el) => {
              if (!el.progress) {
                validChild = false;
              }
            });
          }
        }

        // * VALIDATOR FOR DATEPICKER
        if (
          choiceItem.type === CHOICE_ITEMS_TYPES.TextField &&
          choiceItem.customize &&
          choiceItem.customize.datePicker === '1'
        ) {
          selectedChild = true;
          validChild = false;

          if (choiceItem.value.length === 5) {
            validChild = true;
          }
        }

        if (
          choiceItem.type !== CHOICE_ITEMS_TYPES.Address &&
          choiceItem.type !== CHOICE_ITEMS_TYPES.Attachment &&
          choiceItem.type !== CHOICE_ITEMS_TYPES.PhotoAttachment &&
          choiceItem.type !== CHOICE_ITEMS_TYPES.ContactDetails &&
          !(
            choiceItem.type === CHOICE_ITEMS_TYPES.TextField &&
            choiceItem.customize &&
            choiceItem.customize.datePicker === '1'
          )
        ) {
          if (
            (typeof choiceItem.value === 'number' && choiceItem.value !== 0) ||
            (typeof choiceItem.value === 'string' &&
              choiceItem.value.replace(/\s/g, '') !== '')
          ) {
            selectedChild = true;
            validChild = true;

            if (
              choiceItem.choice_items &&
              choiceItem.choice_items.length &&
              choiceItem.required
            ) {
              validChild = this.validateChoiceItems(choiceItem);
            }
          } else {
            selectedChild = false;
          }
        }

        validationChildren.push({ selectedChild, validChild });
      });

      validationChildren = validationChildren.filter(
        (el) => el.selectedChild === true
      );

      choiceIsValid =
        validationChildren.length &&
        validationChildren.filter((el) => el.validChild === true).length ===
          validationChildren.length
          ? true
          : false;
    } else {
      choice.choice_items.map((choiceItem) => {
        let selectedChild = false;
        let validChild = false;

        if (choiceItem.type === CHOICE_ITEMS_TYPES.Address) {
          selectedChild = true;
          validChild =
            choiceItem.value &&
            choiceItem.value.postcode !== undefined &&
            choiceItem.value.address_line_one !== undefined &&
            // tslint:disable-next-line:max-line-length
            ((choiceItem.value.address_line_one.replace(/\s/g, '') !== '' &&
              choiceItem.value.postcode.replace(/\s/g, '') !== '' &&
              this.validate(
                'postcode',
                choiceItem.value.postcode.replace(/\s/g, '')
              )) ||
              (choiceItem.value.postcode.replace(/\s/g, '') === '' &&
                choiceItem.value.address_line_one.replace(/\s/g, '') === ''))
              ? true
              : false;
        }

        if (choiceItem.type === CHOICE_ITEMS_TYPES.ContactDetails) {
          selectedChild = true;

          validChild =
            choiceItem.value &&
            choiceItem.value.first_name &&
            choiceItem.value.last_name &&
            choiceItem.value.email &&
            choiceItem.value.phones &&
            Array.isArray(choiceItem.value.phones) &&
            choiceItem.value.phones.length
              ? true
              : false;
        }

        // * VALIDATOR FOR ATTACHMENT
        if (
          choiceItem.type === CHOICE_ITEMS_TYPES.Attachment ||
          choiceItem.type === CHOICE_ITEMS_TYPES.PhotoAttachment
        ) {
          selectedChild = true;
          validChild = true;

          // tslint:disable-next-line:no-shadowed-variable
          choiceItem.value.map((el) => {
            if (!el.progress) {
              validChild = false;
            }
          });
        }

        // * VALIDATOR FOR DATEPICKER
        if (
          choiceItem.type === CHOICE_ITEMS_TYPES.TextField &&
          choiceItem.customize &&
          choiceItem.customize.datePicker === '1'
        ) {
          selectedChild = true;
          if (choiceItem.value === '') {
            validChild = true;
          } else if (choiceItem.value.length === 5) {
            validChild = true;
          }
        }

        if (
          choiceItem.type !== CHOICE_ITEMS_TYPES.Address &&
          choiceItem.type !== CHOICE_ITEMS_TYPES.Attachment &&
          choiceItem.type !== CHOICE_ITEMS_TYPES.PhotoAttachment &&
          choiceItem.type !== CHOICE_ITEMS_TYPES.ContactDetails &&
          !(
            choiceItem.type === CHOICE_ITEMS_TYPES.TextField &&
            choiceItem.customize &&
            choiceItem.customize.datePicker === '1'
          )
        ) {
          validChild = true;
          selectedChild = true;
          if (
            (typeof choiceItem.value === 'number' && choiceItem.value !== 0) ||
            (typeof choiceItem.value === 'string' &&
              choiceItem.value.replace(/\s/g, '') !== '')
          ) {
            if (choiceItem.choice_items && choiceItem.choice_items.length) {
              validChild = this.validateChoiceItems(choiceItem);
            }
          } else {
          }
        }

        validationChildren.push({ selectedChild, validChild });
      });

      choiceIsValid = validationChildren.filter((el) => el.validChild === false)
        .length
        ? false
        : true;
    }

    return choiceIsValid;
  }
}
