import { BookingService } from './../booking.service';
import { Injectable } from '@angular/core';
import { BOOKING_API_PATHS, BOOKING_STATE_TOKEN } from './booking.const';
import {
    Action,
    Actions,
    Selector,
    State,
    StateContext,
    Store,
} from '@ngxs/store';
import { tap, map, mergeMap, mapTo, switchMap } from 'rxjs/operators';
import { XrmResourcesService } from '../../../common/services/xrm-resources/xrm-resources.service';
import { BookingActions } from './booking.actions';
import { ApiRequest, ErrorActions, SosApiService } from '../../../common';
import { of } from 'rxjs';
import { BookingsActions } from '../bookings/bookings.actions';

export class BookingStateModel {
    booking: any = null;
    v2booking: any = null;
    bookingSessions: any = [];
    proTracking: any = null;
}

@State<BookingStateModel>({
    name: BOOKING_STATE_TOKEN,
    defaults: new BookingStateModel(),
})
@Injectable()
export class BookingState {
    constructor(
        private sosApiService: SosApiService,
        private xrmResourcesService: XrmResourcesService,
        private bookingService: BookingService,
        private store: Store
    ) {}

    @Action(BookingActions.Refresh)
    refresh(
        { dispatch, getState }: StateContext<BookingStateModel>,
        payload: BookingActions.Refresh
    ) {
        const isBookingTypeSession: boolean = getState().booking && getState().booking.type === 'session' ? true : false,
              bookingId = payload.bookingId,
              isPastBooking: boolean = payload.bookingScreen === 'history' ? true : false;


        // get regular session only on upcoming bookings
        if (isBookingTypeSession && !isPastBooking) {
            return dispatch(new BookingActions.GetRegularSession(bookingId));
        } else {
            return dispatch(new BookingActions.GetSingle(bookingId, isPastBooking)).subscribe(
                () => {
                    this.store.dispatch(new BookingsActions.RefreshSingle(bookingId, isPastBooking))
                }
            );
        }
        
    }

    @Action(BookingActions.GetSingle)
    getSingle(
        { dispatch, getState, patchState }: StateContext<BookingStateModel>,
        payload: BookingActions.GetSingle
    ) {
        return this.xrmResourcesService
            .getBooking(payload.bookingId, payload.isHistory)
            .pipe(
                tap(
                    (response) => {
                        let booking = response.data[0];
                        booking = this.bookingService.extendBookingData(
                            booking
                        );

                        patchState({ booking: booking });

                        if (!payload.isHistory) {
                            if (booking?.type == 'regular' && booking?.objectId) {
                                dispatch(
                                    new BookingActions.GetRegularSessions(
                                        booking?.objectId
                                    )
                                );
                            }
                        }
                        
                        if (booking?.unitStatusOption && booking?.objectId) {
                            dispatch(
                                new BookingActions.GetProTrackingInfo(
                                    booking?.objectId
                                )
                            ).subscribe();
                        }
                        

                    },
                    (error) => {
                        patchState({ booking: null });
                    }
                ),
            );
    }

    @Action(BookingActions.GetRegularSession)
    getRegularSession(
        { patchState, getState, dispatch }: StateContext<BookingStateModel>,
        payload: BookingActions.GetRegularSession
    ) {
        return this.xrmResourcesService
            .getRegularSessions(payload.bookingId.toString())
            .pipe(
                tap(
                    (response) => {
                        let booking = response.data[0];
                        booking = this.bookingService.extendBookingData(
                            booking,
                            false
                        );

                        patchState({ booking: booking });

                        if (booking?.unitStatusOption && booking?.objectId) {
                            dispatch(
                                new BookingActions.GetProTrackingInfo(
                                    booking?.objectId
                                )
                            ).subscribe();
                        }
                    },
                    (error) => {
                        patchState({ booking: null });
                    }
                ),
            );
    }

    /**
     * This method will fetch the data from new endpoint this is still in development endpoint and should be carful using this data
     * @param param0
     * @param payload
     * @returns
     */
    @Action(BookingActions.GetSingleV2)
    getSingleV2(
        { patchState, getState, dispatch }: StateContext<BookingStateModel>,
        payload: BookingActions.GetSingleV2
    ) {
        // The booking id prefix number.
        const bookingIdPrefix = '30000';

        // NOTE REMOVE booking id prefix added from backend. Because new endpoint work with the original id for the booking
        const bookingId = payload.bookingId.replace(bookingIdPrefix, '');

        const path = payload.history
            ? BOOKING_API_PATHS.past_bookings
            : BOOKING_API_PATHS.bookings;

        return this.sosApiService
            .get(
                path + '/' + bookingId
                // , {'expand[0]': 'all.all.all'}
            )
            .pipe(
                tap((response) => {
                    const v2booking = response.data[0];
                    patchState({ v2booking: v2booking });
                })
            );
    }

    @Action(BookingActions.GetRegularSessions)
    getRegularSessions(
        { dispatch, patchState, getState }: StateContext<BookingStateModel>,
        payload: BookingActions.GetRegularSessions
    ) {
        return this.xrmResourcesService
            .getRegularSessions(payload.bookingId)
            .pipe(
                tap(
                    (response) => {
                        patchState({ bookingSessions: response.data });
                    },
                    (error) => {
                        patchState({ bookingSessions: [] });
                    }
                )
            );
    }

    @Action(BookingActions.GetMoreRegularSessions)
    getMoreRegularSessions(
        { dispatch, patchState, getState }: StateContext<BookingStateModel>,
        payload: BookingActions.GetRegularSessions
    ) {
        const state = getState();
        return this.xrmResourcesService
            .getRegularSessions(
                payload.bookingId,
                Math.ceil(state.bookingSessions.length / 6)
            )
            .pipe(
                tap(
                    (response) => {
                        patchState({
                            bookingSessions: [
                                ...state.bookingSessions,
                                ...response.data,
                            ],
                        });
                    },
                    (error) => {
                        patchState({
                            bookingSessions: state.bookingSessions,
                        });
                    }
                )
            );
    }

    @Action(BookingActions.GetProTrackingInfo)
    getProTrackingInfo(
        { patchState, dispatch, getState }: StateContext<BookingStateModel>,
        payload: BookingActions.GetProTrackingInfo
    ) {
        return this.sosApiService
            .get(
                `${BOOKING_API_PATHS.bookings}/${payload.bookingId}/${BOOKING_API_PATHS.unitStatus}`
            )
            .pipe(
                tap(
                    (response) => {
                        patchState({
                            ...getState(),
                            ...{ proTracking: response.data },
                        });
                    },
                    (error) => {
                        patchState({
                            ...getState(),
                            ...{ proTracking: null },
                        });

                        throw error;
                    }
                )
            );
    }

    @Action(BookingActions.AddBookingNote)
    AddBookingNote(
        { patchState, getState, dispatch }: StateContext<BookingStateModel>,
        payload: BookingActions.AddBookingNote
    ) {
        const state = getState();
        const sendData = {
            booking_id: state.booking.objectId,
            note: payload.note,
        };

        return this.sosApiService.post('client/add_note', sendData).pipe(
            switchMap((res) => {
                return dispatch(new BookingActions.Refresh(sendData.booking_id, payload.screen));
            })
        );
    }

    @Action(BookingActions.Reset)
    reset({ patchState }: StateContext<BookingStateModel>) {
        patchState({
            booking: null,
            bookingSessions: [],
            proTracking: null,
        });
    }
}
