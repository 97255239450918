export function convertDeepLinkToObject(
    deepLinkString
): { [key: string]: any } {
    let vars = {},
        hash;
    const hashes = deepLinkString.substr().split('&');
    for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars[decodeURI(hash[0])] = decodeURI(hash[1]);
    }

    return vars;
}
