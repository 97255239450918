import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

export const CHECKBOX_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
};

@Component({
    selector: 'serviceos-ng-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    host: {
        class: 'sos-slide-toggle',
        '[id]': 'id',
        '[attr.name]': 'null',
        '[class.sos-checked]': 'checked',
        '[class.sos-disabled]': 'disabled',
    },
    providers: [CHECKBOX_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
    private _checked = false;

    @Input()
    get checked(): boolean {
        return this._checked;
    }
    set checked(value: BooleanInput) {
        this._checked = coerceBooleanProperty(value);
        this._changeDetectorRef.markForCheck();
    }

    private _uniqueId: string = `sos-checkbox-${++nextUniqueId}`;
    @Input() id: string = this._uniqueId;
    get inputId(): string {
        return `${this.id || this._uniqueId}-input`;
    }

    @Input() indeterminate = false;

    @Input() name: string | null = null;

    @Input() error: boolean = false;

    @Input()
    disabled = false;

    @ViewChild('input') _inputElement!: ElementRef<HTMLInputElement>;

    _onChange = (_: any) => {};

    _onTouched = () => {};

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    writeValue(value: any): void {
        this._checked = !!value;
        this._emitChangeEvent();
        this._changeDetectorRef.detectChanges();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    ngOnInit(): void {}

    _onInputClick(event: Event) {
        this._onTouched();
        event.stopPropagation();
    }

    /** Method being called whenever the underlying input emits a change event. */
    _onChangeEvent(event: Event) {
        event.stopPropagation();

        if (this.disabled) {
            this._inputElement.nativeElement.checked = this.checked;
            return;
        }

        this.checked = this._inputElement.nativeElement.checked;
        this._emitChangeEvent();
    }

    private _emitChangeEvent() {
        this._onChange(this.checked);
    }
}
