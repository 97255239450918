export namespace CreditActivityActions {
    export class GetAll {
        static readonly type = '[CREDIT ACTIVITY] Get All';
    }

    export class LoadMoreCreditActivity {
        static readonly type = '[CREDIT ACTIVITY] Load More Credit Activity';
    }

    export class Reset {
        static readonly type = '[CREDIT ACTIVITY] Reset';
    }
}