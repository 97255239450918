import { filter } from 'rxjs/operators';
import { flatten, get } from './../../../../helpers/object-transformers';
import { SOSTranslateParserService } from './../localization/sostranslate-parser.service';
import { LocalizationSelectors } from './../localization/localization.selectors';
import { ModulesState, ModulesStateModel } from './modules.state';
import { createSelector } from '@ngxs/store';
import { Selector } from '@ngxs/store';
import { deepMerge, refCopy } from '../../../../helpers';
import { ThemeSelectors } from '../theme/theme.selectors';

export class ModulesSelectors {
    static getModuleByName(name: string) {
        return createSelector([ModulesState], (state: ModulesStateModel) => {
            if (Object.keys(state).includes(name)) {
                return state[name];
            } else {
                return null;
            }
        });
    }

    static getGeneralTexts() {
        return createSelector(
            [ModulesSelectors.getModuleByName('customer')],
            (config) => {
                if (config?.texts?.general) {
                    return config.texts.general;
                }

                return null;
            }
        );
    }

    static featureEnabled(feature: string, name: string) {
        return createSelector(
            [ModulesSelectors.getModuleByName(name)],
            (module: ModulesStateModel) => {
                if (module?.settings?.features && module?.settings?.features.length) {
                    return module.settings.features.find(
                        (item) => {
                            return item.name === feature && item.enabled;
                        }
                    ) ? true : false
                } else {
                    return false;
                }
            }
        );
    }

    /**
     * ? Overwrite the module configuration with core theme.
     * ! Maybe revers this logic in feature! Because is more logic core theme by default start and module config bee the updated from client.
     * @param name
     * @param theme
     * @returns
     */
    static getModuleConfigurationForReactNativeModule(name: string) {
        return createSelector(
            [
                ModulesSelectors.getModuleByName(name),
                ThemeSelectors.getTheme,
                ModulesSelectors.getModuleByName('application'),
            ],
            (moduleConfig: ModulesStateModel, theme, appConfig) => {
                let themeColors: any = {};

                if (!moduleConfig) {
                    return null;
                }

                moduleConfig = refCopy(moduleConfig);

                moduleConfig = deepMerge(moduleConfig, appConfig);

                Object.keys(moduleConfig.theme.colors).map((key) => {
                    themeColors[key] = theme[key]
                        ? theme[key]
                        : moduleConfig?.theme.colors[key];
                });

                moduleConfig.theme.typeface = theme['typeface']
                    ? theme['typeface']
                    : moduleConfig.theme.typeface;

                /**
                 * * React native doc: https://reactnative.dev/docs/text#limited-style-inheritance
                 * * You also lose the ability to set up a default font for an entire subtree. Meanwhile, fontFamily only accepts a single font name, which is different from font-family in CSS.
                 */
                //! Adding guidelines web fonts.
                moduleConfig.theme.typeface += `, Trebuchet, 'Century Gothic', 'Segoe UI', sans-serif`;

                moduleConfig.theme.colors = { ...theme, ...themeColors };

                return moduleConfig;
            }
        );
    }

    static getModuleConfigText(name, lang?): any {
        return createSelector(
            [
                ModulesSelectors.getModuleByName(name),
                LocalizationSelectors.getShortcodes()
            ],
            (mConfig: any, shortCodes) => {
                if (mConfig?.texts) {

                    //Create local translate parser service.
                    const sosTranslateParser = new SOSTranslateParserService();

                    let alltexts = mConfig?.texts;

                    // replace shortcodes
                    alltexts = sosTranslateParser.interpolate(
                        alltexts,
                        {
                            ...shortCodes,
                        }
                    );
                    
                    return { ...alltexts, components: mConfig.components || {} };
                }

                return {};
            }
        );
    }

    /**
     * Return the component config after applying shortcodes and texts.
     * @param moduleName
     * @param componentSelector
     * @returns
     */
    static getComponentFromModule(
        moduleName: string,
        componentSelector: string
    ) {
        return createSelector(
            [
                ModulesSelectors.getModuleByName(moduleName),
                LocalizationSelectors.getShortcodes(),
                ModulesSelectors.getModuleConfigText(moduleName, undefined),
                ModulesSelectors.getModuleConfigText('customer', undefined),
            ],
            (config, shortCodes, moduleTexts, customerTexts) => {
                if (config?.components) {
                    let componentConfig = get(
                        config.components,
                        componentSelector
                    );

                    if (!componentConfig) {
                        return null;
                    }

                    // if (
                    //     componentConfig.hasOwnProperty('active') &&
                    //     componentConfig.active == false
                    // ) {
                    //     return null;
                    // }

                    //Create local translate parser service.
                    const sosTranslateParser = new SOSTranslateParserService();

                    //Customer flatt texts selectors.
                    let customer: any = {
                        customer: { texts: customerTexts },
                        texts: moduleTexts,
                    };

                    customer = flatten(customer);

                    componentConfig = sosTranslateParser.interpolate(
                        componentConfig,
                        {
                            ...customer,
                            ...shortCodes,
                        }
                    );

                    return componentConfig;
                }
                return null;
            }
        );
    }

    static getImageFromModule(moduleName: string, imageKey: string) {
        return createSelector(
            [ModulesSelectors.getModuleByName(moduleName)],
            (config) => {
                if (config?.assets?.images && config.assets.images[imageKey]) {
                    return config.assets.images[imageKey];
                }

                return null;
            }
        );
    }

    /**
     * Search in all modules configurations and return first find settings.
     * If is pass array with params will return object with {[key]:value}
     * @param name
     * @returns
     */
    static getSetting(name: string | string[]) {
        return createSelector([ModulesState], (state: ModulesStateModel) => {
            if (typeof name == 'string') {
                return Object.entries(state)
                    .map(([key, config]) => config)
                    .find((config) => {
                        return config.settings && (config.settings[name] || config.settings[name] === false)
                            ? true
                            : false;
                    })?.settings[name];
            } else {
                const response = {};

                name.forEach((key) => {
                    if (response)
                        response[key] = Object.entries(state)
                            .map(([key, config]) => config)
                            .find((config) => {
                                return config.settings && (config.settings[key] || config.settings[key] === false)
                                    ? true
                                    : false;
                            })?.settings[key];
                });

                return Object.keys(response).length ? response : null;
            }
        });
    }
}
