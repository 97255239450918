import { TranslateService } from '@ngx-translate/core';
import {
    ERROR_CODES,
    HandlerErrorService,
    ModalsService,
    DefaultModalConfig,
    DefaultModalComponent,
    AuthorizationActions,
    SosLoaderService,
} from '@serviceos-ng/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Injectable({
    providedIn: 'root',
})
export class AccountsHandlerErrorService extends HandlerErrorService {
    constructor(
        private modalsService: ModalsService,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        translateService: TranslateService,
        private sosLoaderService: SosLoaderService
    ) {
        super(translateService);
    }

    handler(errorObject) {
        switch (errorObject.error.code) {
            case ERROR_CODES.Custom.Booking_Missing.code:
            case ERROR_CODES.Bookings.Invalid_regular.code:
                this.openStandardModal(errorObject).subscribe((res) => {
                    errorObject.errorSubject.next(res);
                    this.router.navigate(['bookings']);
                });

                break;
            case ERROR_CODES.Common.Unauthorized.code:
                this.store.dispatch(new AuthorizationActions.Logout());
                // this.openStandardModal(errorObject).subscribe((res) => {
                    this.sosLoaderService.showLoader();
                    errorObject.errorSubject.next();
                    window.location.reload();
                // });
                break;
            case ERROR_CODES.Javascript.Lazy_load_chunk.code:
                this.openStandardModal(errorObject).subscribe((res) => {
                    errorObject.errorSubject.next(res);
                    window.location.reload();
                });
                break;
            default:
                this.openStandardModal(errorObject).subscribe((res) => {
                    errorObject.errorSubject.next(res);
                });

                break;
        }
    }

    private openStandardModal(errorObject) {
        return this.modalsService.open(
            new DefaultModalConfig(
                '',
                errorObject.error.message,
                [
                    {
                        title: 'Ok',
                        type: 'close',
                    },
                ],
                'error'
            ),
            { backdropClickClose: false }
        ).close$;
    }
}
