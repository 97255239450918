import { AuthorizationSelectors } from './../../authorization-store/authorization.selectors';
import { XrmResourcesService } from './../../../common/services/xrm-resources/xrm-resources.service';
import { Injectable } from '@angular/core';
import { BOOKINGS_STATE_TOKEN, BOOKINGS_API_PATHS } from './bookings.const';
import {
    Action,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
    Store,
} from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { BookingsActions } from './bookings.actions';
import { ApiRequest, ErrorActions, SosApiService } from '../../../common';
import { interval, Observable, of, Subscription } from 'rxjs';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { BookingService } from '../booking.service';

export class BookingsStateModel {
    bookings: any[] = [];
    bookingsLength: number = 0; // not working on v1
    pastBookings: any[] = [];
    pastBookingsLength: number = 0;
}

@State<BookingsStateModel>({
    name: BOOKINGS_STATE_TOKEN,
    defaults: new BookingsStateModel(),
})
@Injectable()
export class BookingsState implements NgxsOnInit {
    private intervalUpdate: Subscription | null = null;

    constructor(
        private sosApiService: SosApiService,
        private xrmResourcesService: XrmResourcesService,
        private bookingService: BookingService,
        private store: Store
    ) {}

    ngxsOnInit(ctx: StateContext<any>) {
        // Functionality to refetch bookings.
        this.store
            .select(AuthorizationSelectors.isAuthorize)
            .subscribe((isAuthorize) => {
                if (isAuthorize && !this.intervalUpdate) {
                    this.intervalUpdate = interval(1000 * 60 * 30).subscribe(
                        (intervalPass) => {
                            ctx.dispatch(new BookingsActions.GetAll(false));
                        }
                    );

                    ctx.dispatch(new BookingsActions.GetAll(false));
                } else {
                    this.intervalUpdate?.unsubscribe();
                    this.intervalUpdate = null;
                }
            });
    }

    @Action(BookingsActions.GetAll)
    getAll(
        { dispatch, patchState }: StateContext<BookingsStateModel>,
        payload: BookingsActions.GetAll
    ) {
        // const requestObservable: Observable<any> = this.xrmResourcesService.getBookings(payload.isHistory);
        const requestObservable: Observable<any> = payload.isHistory
            ? this.sosApiService.get(
                  BOOKINGS_API_PATHS.past_bookings,
                  payload.params
              )
            : this.xrmResourcesService.getBookings(payload.isHistory);

        const statePropertyName: string = payload.isHistory
            ? 'pastBookings'
            : 'bookings';

        let stateObject: any = {};

        return requestObservable.pipe(
            tap(
                (response) => {
                    const allBookings = response.data;

                    stateObject[statePropertyName] = allBookings.map(
                        (booking) => {
                            return this.bookingService.extendBookingData(
                                booking,
                                payload.isHistory
                            );
                        }
                    );

                    if (payload.isHistory) {
                        stateObject.pastBookingsLength = response.paging?.total;
                    }

                    patchState(stateObject);
                },
                (error) => {
                    stateObject[statePropertyName] = null;

                    if (payload.isHistory) {
                        stateObject.pastBookingsLength = 0;
                    }

                    patchState(stateObject);
                }
            )
        );
    }

    @Action(BookingsActions.LoadMoreBookings)
    getMoreRegularSessions(
        { dispatch, patchState, getState }: StateContext<BookingsStateModel>,
        payload: BookingsActions.LoadMoreBookings
    ) {
        const state = getState();
        const params = {
            expand: 'all',
            'paging[offset]': state.pastBookings.length,
            'paging[limit]': 24,
            ...payload.params,
        };

        return this.sosApiService
            .get(BOOKINGS_API_PATHS.past_bookings, params)
            .pipe(
                tap(
                    (response) => {
                        const extendedBookings = response.data.map(
                            (booking) => {
                                return this.bookingService.extendBookingData(
                                    booking,
                                    payload.isHistory
                                );
                            }
                        );

                        patchState({
                            pastBookings: [
                                ...state.pastBookings,
                                ...extendedBookings,
                            ],
                            pastBookingsLength: response.paging?.total,
                        });
                    },
                    (error) => {
                        patchState({
                            pastBookings: state.pastBookings,
                        });
                    }
                )
            );
    }

    @Action(BookingsActions.RefreshSingle)
    refreshSingle(
        { dispatch, getState, patchState }: StateContext<BookingsStateModel>,
        payload: BookingsActions.RefreshSingle
    ) {
        const requestObservable: Observable<any> = payload.isHistory
            ? this.sosApiService.get(
                  BOOKINGS_API_PATHS.past_bookings + '/' + payload.bookingId,
                  { expand: 'all' }
              )
            : this.xrmResourcesService.getBooking(
                  payload.bookingId,
                  payload.isHistory
              );

        return requestObservable.pipe(
            tap(
                (response) => {
                    let newBooking = this.bookingService.extendBookingData(
                        response.data[0],
                        payload.isHistory
                    );
                    dispatch(
                        new BookingsActions.UpdateSingleBooking(
                            newBooking,
                            payload.isHistory
                        )
                    );
                },
                (error) => {}
            )
        );
    }

    @Action(BookingsActions.UpdateSingleBooking)
    updateSingleBooking(
        { setState }: StateContext<BookingsStateModel>,
        payload: BookingsActions.UpdateSingleBooking
    ) {
        setState(
            payload.isHistory
                ? patch({
                      pastBookings: updateItem(
                          (booking) => booking?.id === payload.newBooking.id,
                          payload.newBooking
                      ), // v2
                  })
                : patch({
                      bookings: updateItem(
                          (booking) =>
                              booking?.objectId === payload.newBooking?.objectId,
                          payload.newBooking
                      ), // v1
                  })
        );
    }

    @Action(BookingsActions.ResetAll)
    resetAll({ patchState }: StateContext<BookingsStateModel>) {
        patchState({ bookings: [] });
    }
}
