import { ModulesState } from './modules/modules.state';
import { FeaturesState } from './features/features.state';
import { ThemeState } from './theme/theme.state';
import { TrackingState } from './tracking/tracking.state';
import { NgxsModule } from '@ngxs/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreState } from './core.state';
import { LocalizationState } from './localization/localization.state';
import { ValidationsState } from './validations/validations.state';


@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      CoreState,
      TrackingState,
      LocalizationState,
      ThemeState,
      FeaturesState,
      ModulesState,
      ValidationsState
    ])
  ]
})
export class SosCoreStoreModule { }
