import { TreatsState } from './treats/treats.state';
import { AccountState } from './account.state';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NotificationsState } from './notifications/notifications.state';
import { CreditActivityState } from './credit-activity/credit-activity.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      AccountState, 
      TreatsState, 
      NotificationsState,
      CreditActivityState
    ])
  ],
})
export class SosAccountStoreModule { }
