import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
    transform(dataArray: Array<any>, filterProperty): Array<any> {
		if (!Array.isArray(dataArray) || !filterProperty) {
            return dataArray;
        }
		
        return dataArray.filter((obj: any) => obj[filterProperty]);
    }
}
