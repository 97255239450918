import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SosLoaderComponent } from './loader.component';

import { genLongGUID } from '../../helpers'
@Injectable({
  providedIn: 'root'
})
export class SosLoaderService {
  // TODO this need to be upgraded to support multiple loaders.
  public loaderComponentRef!: SosLoaderComponent;
  public loaderStack: unknown[] = [];
  public loaderStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Set the new loader reference to the loaderComponent
   * @param ref
   */
  public setLoaderRef(ref: SosLoaderComponent): void {
    this.loaderComponentRef = ref;

    if (this.loaderComponentRef && !this.loaderStack.length) {
      this.loaderComponentRef.hide();
    } else {
      this.loaderComponentRef.settings = (this.loaderStack[this.loaderStack.length - 1] as SosLoaderComponent).settings;
    }
  }

  /**
   * Show the loader
   * @param loaderText
   * @param overlayEnabled
   */
  public showLoader(loaderText?: string, overlayEnabled = true, isInvisible = false) {
    loaderText = loaderText || '';

    const loader = {
      loaderId: genLongGUID(),
      settings: {
        text: loaderText,
        overlayEnabled,
        isInvisible,
      },
    };

    this.loaderStack.push(loader);

    if (this.loaderComponentRef) {
      this.loaderComponentRef.settings = loader.settings;
      this.loaderComponentRef.show(loaderText, overlayEnabled, isInvisible);
    }

    this.loaderStatus.next(!!this.loaderStack.length);

    return loader;
  }

  /**
   * Hide the loader
   */
  public hideLoader(loader: any) {

    if (!loader) {
      return;
    }

    const loaderIndex = this.loaderStack.indexOf((el: any) => {
      return el.loaderId === loader.loaderId;
    });

    this.loaderStack.splice(loaderIndex, 1);

    if (this.loaderComponentRef && !this.loaderStack.length) {
      this.loaderComponentRef.hide();
    } else if (this.loaderComponentRef) {
      this.loaderComponentRef.settings = (this.loaderStack[this.loaderStack.length - 1] as SosLoaderComponent).settings;
    }
    this.loaderStatus.next(!!this.loaderStack.length);
  }

  public hardCloseLoader() {
    this.loaderStack = [];
    if (this.loaderComponentRef && !this.loaderStack.length) {
      this.loaderComponentRef.hide();
    }
    this.loaderStatus.next(!!this.loaderStack.length);

  }
}
