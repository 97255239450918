import { SidebarComponent } from './sidebar/sidebar.component';
import { DialogComponent } from './dialog/dialog.component';
import { OverlayRef } from '@angular/cdk/overlay';
import { deepMerge } from '../../helpers';
import { Subject } from 'rxjs';


export class ModalOverlayRef {
  public configData;
  public close$ = new Subject();

  constructor(
    private overlayRef: OverlayRef,
    private modalComponent: DialogComponent | SidebarComponent,
    modalData
  ) {
    // TODO make modalData to just public this will make modalData to be only read only. this is temp solution for development.
    this.configData = deepMerge({}, modalData);
  }

  close(data?): void {
    // NOTE we trigger animation and then after animation is complete close the overlay.
    this.modalComponent.closeAnimation();
    this.close$.next(data);
    setTimeout(() => {
      this.overlayRef.dispose();
    }, 500);
  }
}
