<div class="breadcrumbs-holder">
    <ul>
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">


            <li [ngClass]="{'breadcrumb-link': breadcrumb.url, 'current-page': !breadcrumb.url }">
                <span  *ngIf="breadcrumb.url; else no_link_breadcrumb"
                    (click)="navigateTo(breadcrumb.url)">{{ breadcrumb.title }}</span>

                <ng-template #no_link_breadcrumb>
                    <span>{{ breadcrumb.title }}</span>
                </ng-template>
            </li>

            <li *ngIf="breadcrumbs && i < breadcrumbs.length - 1">
                <serviceos-ng-system-icon class="sos-icon" [icon]="'arrow-right'">
                </serviceos-ng-system-icon>
            </li>
        </ng-container>
    </ul>
</div>