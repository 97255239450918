<div class="sos-radio-button-box">
    <div class="sos-radio-content pointer" (click)="onClick()">
        <span class="sos-check-icon-wrap sos-setting_{{ settings?.id }}"
            [ngClass]="{ 'sos-active': settings?.value }">
        </span>
        <span class="sos-item-title">
            <span class="item-title-cell">{{ settings?.title }}</span>
            <!-- <span class="align-vertical-icon" *ngIf="
                    settings &&
                    settings.customize &&
                    settings.customize.obf_additional_info
                ">
                <span appInfoModal [additionalInfo]="
                        settings.customize.obf_additional_info
                    " class="sos-icon icon-si-info-circle"></span>
            </span> -->
        </span>
        <span class="sos-subtitle" *ngIf="settings.description">{{
            settings.description
        }}</span>
    </div>
</div>