import { filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthorizationModel } from './authorization.state';
import { AuthorizationSelectors } from './authorization.selectors';
import { SosLoaderService } from '../../elements';
@Injectable({
    providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
        private loaderService: SosLoaderService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const loader = this.loaderService.showLoader();
        return this.store.select(AuthorizationSelectors.getAuthorization).pipe(
            filter((data) => {
                if (typeof data == 'undefined') {
                    console.warn(
                        'BE SURE YOU ARE IMPORTING THE STORE WHERE YOU USE THE GUARD BEFORE LOSING MORE TIME DEBUGGING'
                    );
                }

                return data?.status !== 'pending';
            }),
            map((authorize: AuthorizationModel) => {
                this.loaderService.hideLoader(loader);
                if (!authorize?.sid) {
                    return this.router.createUrlTree(['auth']);
                } else {
                    return true;
                }
            })
        );
    }

    canLoad(
        route: Route,
        aRoute: ActivatedRouteSnapshot,
        segments: UrlSegment[]
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
            const loader = this.loaderService.showLoader();
            return this.store.select(AuthorizationSelectors.getAuthorization).pipe(
                filter((data) => {
                    if (typeof data == 'undefined') {
                        console.warn(
                            'BE SURE YOU ARE IMPORTING THE STORE WHERE YOU USE THE GUARD BEFORE LOSING MORE TIME DEBUGGING'
                        );
                    }
    
                    return data?.status !== 'pending';
                }),
                map((authorize: AuthorizationModel) => {
                    this.loaderService.hideLoader(loader);
                    if (!authorize?.sid) {
                        return this.router.createUrlTree(['auth'], { queryParams: aRoute.queryParams});
                    } else {
                        return true;
                    }
                })
            );
    }
}
