import { CreditActivityActions } from './credit-activity.actions';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CREDIT_ACTIVITY_STATE_TOKEN } from './credit-activity.consts';
import { CreditActivityService } from './credit-activity.service';



export class CreditActivityStateModel {
	creditActivity: any[] = [];
	creditActivityLength: number = 0;
}

@State<CreditActivityStateModel>({
    name: CREDIT_ACTIVITY_STATE_TOKEN,
    defaults: new CreditActivityStateModel(),
})
@Injectable()
export class CreditActivityState {
    constructor(private creditActivityService: CreditActivityService) {}

    @Action(CreditActivityActions.GetAll)
    getAll(
        { patchState }: StateContext<CreditActivityStateModel>
    ) {
		const params = {'paging[limit]': 7};

        return this.creditActivityService.getAll(params).pipe(
            tap(
                (response) => {
                    patchState({ creditActivity: [...response.data], creditActivityLength: response.paging?.total });
                },
                (error) => {
                    patchState({ creditActivity: [], creditActivityLength: 0 });
                }
            )
        );
    }

	@Action(CreditActivityActions.LoadMoreCreditActivity)
    LoadMoreCreditActivity(
        { dispatch, patchState, getState }: StateContext<CreditActivityStateModel>
    ) {
        const state = getState();
		const params = { 'paging[offset]': state.creditActivity.length, 'paging[limit]': 7};

		return this.creditActivityService.getAll(params).pipe(
            tap(
                (response) => {
                    patchState({ creditActivity: [...state.creditActivity, ...response.data], creditActivityLength: response.paging?.total });
                },
                (error) => {
                    patchState({ creditActivity: [...state.creditActivity], creditActivityLength: state.creditActivityLength });
                }
            )
        );
    }

	@Action(CreditActivityActions.Reset)
    Reset({ patchState }: StateContext<CreditActivityStateModel>) {
        patchState({ creditActivity: [] });
    }
}
