import { Component, Input, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

// interface carouselItem {
// 	image: string,
// 	title?: string,
// 	description?: string
// }

@Component({
    selector: 'serviceos-ng-carousel-slider',
    templateUrl: './carousel-slider.component.html',
    styleUrls: ['./carousel-slider.component.scss'],
})
export class CarouselSliderComponent implements OnInit {

	@Input()
	carouselData: any = [];

    @Input()
	showNavigationArrows: any = true;

    @Input()
	isFullWidth: any = false;

    constructor(config: NgbCarouselConfig) {
        config.interval = 5000;
        config.wrap = true;
        config.keyboard = false;
        config.pauseOnHover = false;
        config.showNavigationArrows = true;
        config.showNavigationIndicators = true;
        config.animation = true;
    }

    ngOnInit(): void {}
}
