<ng-container [ngSwitch]="item">
    <ng-container *ngSwitchCase="'counter'">
        <span class="tag" #tag [ngClass]="{ circle: tag.innerText.length < 2 }"
            ><ng-content></ng-content
        ></span>
    </ng-container>
    <span *ngSwitchCase="'exclamation'" class="tag circle"
        ><serviceos-ng-system-icon
            [icon]="'exclamation'"
        ></serviceos-ng-system-icon
    ></span>
    <span *ngSwitchDefault class="tag">
        {{ item }}
    </span>
</ng-container>
