import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'serviceos-ng-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  @ViewChild('descriptionElement') errorMessage;

  constructor() {
    
  }

  ngOnInit(): void {
  }

}
