import { first } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ApiRequest } from './../../services/api/api.service';
import { Injectable } from '@angular/core';
import { ERROR_CODES } from './error.const';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class HandlerErrorService {
    constructor(private translateService: TranslateService) {}

    handler(error) {
        error.errorSubject.next();
    }

    handle(error, global = false) {

        if (error && error.stack && JSON.stringify(error.stack).indexOf('freshchat') !== -1) {
            // do not show error popups on freshchat error when ads blocker is enabled
            return;
        } 

        const errorSubject = new Subject();

        if (error instanceof ApiRequest && error.error && error.error.length) {
            this.handler({
                type: global ? 'global' : 'local',
                errorType: 'API',
                error: error.error[0],
                errorSubject,
            });

            return errorSubject;
        }

        let message =
            'Oops! Seems like something went wrong on our end! Please accept our apologies and try again.';

        this.handler({
            type: global ? 'global' : 'local',
            errorType: 'JS',
            error: {
                code: this.determineErrorCode(error),
                message: message,
            },
            errorSubject,
        });

        return errorSubject;
    }

    determineErrorCode(error) {
        if (error && error.message.includes('ChunkLoadError')) {
            return ERROR_CODES.Javascript.Lazy_load_chunk.code; // 100001
        }

        if (error && error.stack && JSON.stringify(error.stack).indexOf('freshchat') !== -1) {
            return ERROR_CODES.Javascript.Chat.code; // 100003
        }

        return ERROR_CODES.Javascript.Unknown.code; //100002
    }
}
