import { SystemIconModule } from './../base/system-icon/system-icon.module';
import { ClickOutSideModule } from './../base/click-out-side/click-out-side.module';
import { InputModule } from './../base/input/input.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioItemComponent } from './choice-item/radio-item/radio-item.component';
import { CheckboxItemComponent } from './choice-item/checkbox-item/checkbox-item.component';
import { TextFieldItemComponent } from './choice-item/text-field-item/text-field-item.component';
import { ChoiceItemComponent } from './choice-item/choice-item.component';
import { ChoiceComponent } from './choice/choice.component';
import { DefaultComponent } from './choice/default/default.component';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { RateItemComponent } from './choice-item/rate-item/rate-item.component';
import { LinkModule } from '../base/link/link.module';
import { AlertItemComponent } from './choice-item/alert-item/alert-item.component';
import { AlertModule } from '../base/alert/alert.module';
import { DropdownItemComponent } from './choice-item/dropdown-item/dropdown-item.component';

@NgModule({
    declarations: [
        RadioItemComponent,
        CheckboxItemComponent,
        TextFieldItemComponent,
        ChoiceItemComponent,
        ChoiceComponent,
        DefaultComponent,
        RateItemComponent,
        AlertItemComponent,
        DropdownItemComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        InputModule,
        NgbDatepickerModule,
        ClickOutSideModule,
        LinkModule,
        SystemIconModule,
        AlertModule,
    ],
    exports: [ChoiceComponent],
})
export class ChoicesModule {}
