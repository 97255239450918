import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    localStorageEnabled;

    constructor() {
        this.localStorageEnabled = this.isEnabled;
    }

    public setItem(key: string, value: any) {
        if (this.localStorageEnabled) {

            localStorage.setItem(key, this.IsJsonString(value) ? JSON.stringify(value) : value);
        }
    }

    public getItem(key: string) {
        if (this.localStorageEnabled) {
            const item = localStorage.getItem(key) || '';
            if (item) {
                return this.IsJsonString(item) ? JSON.parse(item) : item;
            }
        }

        return null;
    }

    public removeItem(key: string) {
        if (this.localStorageEnabled) {
            localStorage.removeItem(key);
        }
    }

    public clear() {
        if (this.localStorageEnabled) {
            localStorage.clear();
        }
    }

    public keysWithCertainWordInside(word): string[] {
        const keys: string[] = [];

        if (this.localStorageEnabled) {
            Object.keys(localStorage).forEach((key) => {
                if (key.includes(word)) {
                    keys.push(key);
                }
            });
        }

        return keys;
    }

    public isEnabled() {
        const key = (Math.random() + 1).toString(36).substring(2);
        try {
            localStorage.setItem('test-' + key, key);
            localStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    }

    private IsJsonString(str) {
        try {
          const json = JSON.parse(str);
          return (typeof json === 'object');
        } catch (e) {
          return false;
        }
    }
}
