<label [for]="inputId">
    <input
        (change)="_onChangeEvent($event)"
        #input
        (click)="_onInputClick($event)"
        type="checkbox"
        [name]="name"
        [disabled]="disabled"
        [checked]="checked"
        [id]="inputId"
    />
    <div
        class="checkbox-wrap"
        [ngClass]="{
            checked: checked,
            disabled: disabled,
            indeterminate: indeterminate,
            error: error
        }"
    >
        <div class="checkbox">
            <div class="checkbox-check"></div>
        </div>
        <div class="checkbox-label">
            <ng-content></ng-content>
        </div>
    </div>
</label>
