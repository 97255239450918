export const NAVIGATION_STATE_TOKEN = 'NAVIGATION';

export const NAVIGATION_API_PATHS = {
};



export interface NavigationItemInterface {
    icon: string;
    icon_url?: string;
    name: string;
    title: string;
    path: string;
    count?: number;
    sort: number;
    tag: string;
    requiredModules?: string[];
}

export interface RouteConfigInterface {
    pathname: string,
    params: string,
    data: {
        [key: string]: string
    }
}