import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageColorFilterDirective } from './image-color-filter.directive';



@NgModule({
  declarations: [
    ImageColorFilterDirective
],
imports: [
    CommonModule
],
exports: [
    ImageColorFilterDirective
  ]
})
export class ImageColorFilterModule { }
