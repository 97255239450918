import { Injectable, Injector } from '@angular/core';
import { MODULES_TOKEN } from './modules.const';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ModulesActions } from './modules.actions';

export class ModulesStateModel {
  [key: string]: any
}

@State<ModulesStateModel>({
  name: MODULES_TOKEN,
  defaults: {},
})
@Injectable()
export class ModulesState {

  constructor() {

  }

  @Action(ModulesActions.SetModules)
  setModules({ setState }: StateContext<ModulesStateModel[]>, payload) {
    setState(payload.modules);
  }

  @Action(ModulesActions.SetModule)
  setModule({ setState, getState }: StateContext<ModulesStateModel[]>, payload) {
    
    const state = getState();

    if (state && state[payload.storeName]) {
      
      // update single module in state
      state[payload.storeName] = payload.module;
      
      const modules = state;
      setState(modules);
    }
  }
}
