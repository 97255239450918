import { ModalConfig } from './../modals.service';
import {
    Component,
    AfterContentInit,
    Type,
    ComponentFactoryResolver,
    ChangeDetectorRef,
    ViewChild,
    ViewContainerRef,
    Injector,
    Input,
} from '@angular/core';
import {
    bounceInOnEnterAnimation,
    bounceOutOnLeaveAnimation,
} from 'angular-animations';
import { ModalOverlayRef } from '../modal-overlay-ref';

@Component({
    selector: 'serviceos-ng-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    animations: [
        bounceInOnEnterAnimation({ duration: 500 }),
        bounceOutOnLeaveAnimation({ duration: 500 }),
    ],
})
export class DialogComponent implements AfterContentInit {
    public visible = false;

    @ViewChild('componentContainer', { read: ViewContainerRef, static: false })
    public componentContainer!: ViewContainerRef;

    public config!: ModalConfig;
    public modalRef!: ModalOverlayRef;
    public contentComponentType!: Type<any>;
    public componentRef;

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly cdr: ChangeDetectorRef
    ) {}

    ngAfterContentInit() {
        this.visible = true;
        setTimeout(() => {
            if (this.componentContainer) this.renderComponent();
        }, 10);
    }

    closeAnimation() {
        this.visible = false;
        this.cdr.detectChanges();
    }

    close(): void {
        this.modalRef.close();
    }

    renderComponent() {
        const component = this.componentFactoryResolver.resolveComponentFactory(
            this.contentComponentType
        );
        this.componentContainer?.clear();

        this.componentRef = this.componentContainer?.createComponent(
            component,
            0,
            Injector.create({
                providers: [
                    { provide: ModalOverlayRef, useValue: this.modalRef },
                ],
            })
        );
        
        this.cdr.detectChanges();

    }
}
