
/**
 * This function is use to convert error to simple object.
 * @param key 
 * @param value 
 * @returns 
 */
export function convertErrorToObject(error): {[key:string]: any} {
    if (error instanceof Error) {
        var convertError = {};

        Object.getOwnPropertyNames(error).forEach(function (propName) {
            convertError[propName] = error[propName];

            // if(propName == 'message' || propName == 'stack')
            // convertError[propName] = error[propName];
            // else {

            // }
        });

        return convertError;
    } 
    return error;
}