import { NavigationItemInterface } from "./navigation.const";

export namespace NavigationActions {
    export class Initialize {
        static readonly type = `[Navigation] Initialize`;
        constructor() {}
    }

    /**
     * Add Navigation Items to current Array
     */
    export class AddNavigationItems {
        static readonly type = `[Navigation] Add Navigation Items`;
        constructor(public navigationItems: NavigationItemInterface[]) {}
    }

    export class UpdateCounter {
        static readonly type = `[Navigation] Update Counter`;
        constructor(public path: string, public count: number) {}
    }

    export class SetRouteConfig {
        static readonly type = `[Navigation] Set Route Config`;
        constructor(public pathname: any, public params: any) {}
    }

    export class ClearRouteConfig {
        static readonly type = `[Navigation] Clear Route Config`;
        constructor() {}
    }
}
