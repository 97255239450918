import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes/notes.component';
import { LinkModule } from './../shared/base/link/link.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalLoaderModule } from './../local-loader/local-loader.module';
import { SystemIconModule } from './../shared/base/system-icon/system-icon.module';
import { InputModule } from '../shared/base/input/input.module';

@NgModule({
  declarations: [
    NotesComponent
  ],
  imports: [
    CommonModule,
    LinkModule,
    ReactiveFormsModule,
    LocalLoaderModule,
    SystemIconModule,
    InputModule
  ],
  exports: [
    NotesComponent
  ]
})
export class NotesModule { }
