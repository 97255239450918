import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { ApiRequest, Environment } from '../../../common';
import { popupCenter, SosCryptoService } from '../../../helpers';
import { AuthorizationData, SocialProvider } from '../authentication-types';
import { BaseSocialAuth } from './base-social-auth';
/**
 * Documentation:
 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/openid-connect
 * Ticket:
 * https://pm.1dxr.com/issues/69712
 *
 */
@Injectable({
    providedIn: 'root',
})
export class AzureAuthService extends BaseSocialAuth {
    authPaths: { login: string } = {
        login: '/ext/azure-login',
    };

    public popupInstance: any = null;

    constructor(cryptoService: SosCryptoService, env: Environment, translate: TranslateService) {
        super(cryptoService, env, translate);
    }

    public beginAuth(socialProvider: SocialProvider): Promise<any> {
        return new Promise<ApiRequest<AuthorizationData>>((resolve: Function, reject: Function) => {
            // This need to be change when we have one authorization location for all clients
            const authorizationDomain =
                window.location.origin + this.authPaths.login;

            let authorizationResponse: string | null = null;

            const azurePostMessageHandler = (event) => {
                if (event?.data?.c_azure_login_data) {
                    authorizationResponse = event.data.c_azure_login_data;
                }
            };

            // This is setup for new authorization logic.
            const state = this.createState();

            const authUrl = `https://${socialProvider.data.tenant}.b2clogin.com/${
                socialProvider.data.tenant
            }.onmicrosoft.com/oauth2/v2.0/authorize?p=${
                socialProvider.data.policy
            }&client_id=${socialProvider.data.client_id}&nonce=${encodeURIComponent(
                state
            )}&redirect_uri=${authorizationDomain}&scope=openid&response_type=id_token&prompt=login`;

            const apiRequest = new ApiRequest({
                type: 'POST',
                url: authUrl,
                data: null,
                options: null,
            });

            window.addEventListener('message', azurePostMessageHandler);

            const centerPopup = popupCenter();

            const azurePopup = window.open(
                authUrl,
                'Booking Form Authorization Modal',
                'height=710,width=630,left=' +
                    centerPopup.left +
                    ',top=' +
                    centerPopup.top +
                    ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no'
            );

            this.popupInstance = azurePopup;

            const subscription = interval(1000).subscribe(async() => {
                if (azurePopup && !azurePopup.closed) {
                    return;
                }

                if (authorizationResponse == null) {
                    try {
                        authorizationResponse = localStorage.getItem(
                            'c_azure_login_data'
                        );
                        localStorage.removeItem('c_azure_login_data');
                    } catch (e) {}
                }

                if (authorizationResponse) {
                    const socialData: any = this.cryptoService.decryptData(
                        authorizationResponse,
                        this.env.cookieKey
                    );

                    apiRequest.setResponse(socialData);
                    if (socialData && !socialData.error) {
                        resolve(apiRequest);
                    } else {
                        reject(apiRequest);
                    }
                } else {
                    apiRequest.setResponse(
                       await this.unexpectedErrorWhenAuthorize(socialProvider)
                    );
                    reject(apiRequest);
                }

                window.removeEventListener('message', azurePostMessageHandler);
                subscription.unsubscribe();
            });
        });
    }
}
