<div class="sos-alert {{type}}">
    <div class="icon-holder" [ngSwitch]="type">
        <serviceos-ng-system-icon *ngSwitchCase="'error'" class="sos-icon" [icon]="'close-circle'">
        </serviceos-ng-system-icon>

        <serviceos-ng-system-icon *ngSwitchCase="'warning'" class="sos-icon" [icon]="'important-circle'">
        </serviceos-ng-system-icon>

        <serviceos-ng-system-icon *ngSwitchCase="'success'" class="sos-icon" [icon]="'check-cirlce'">
        </serviceos-ng-system-icon>

        <serviceos-ng-system-icon *ngSwitchDefault class="sos-icon" [icon]="'info-circle'">
        </serviceos-ng-system-icon>
    </div>
    <div class="description">
        <ng-content>


        </ng-content>
    </div>
</div>