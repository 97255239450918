import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { SosCoreConfig } from '../../../core-config';
import { ValidationsSelectors } from '../../stores/core-store/validations/validations.selectors';
import { SosApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class ValidationsService {
    private customPatterns = {
        credit_cards: [
            {
                type: 'amex',
                pattern: /^3[47]/,
                valid_length: [15],
                beautyType: 'American Express',
            },
            {
                type: 'visa_electron',
                pattern: /^(4026|417500|4508|4844|491(3|7))/,
                valid_length: [16],
                beautyType: 'Visa Electron',
            },
            {
                type: 'visa',
                pattern: /^4/,
                valid_length: [16],
                beautyType: 'Visa',
            },
            {
                type: 'mastercard',
                pattern: /^5[1-5]/,
                valid_length: [16],
                beautyType: 'Master Card',
            },
            {
                type: 'maestro',
                pattern: /^(5018|5020|5038|6304|6759|676[1-3])/,
                valid_length: [12, 13, 14, 15, 16, 17, 18, 19],
                beautyType: 'Maestro',
            },
        ],

        cvvCode: {
            pattern: /^[0-9]{3,4}$/,
        },

        nameOnCard: {
            pattern: /^([^!{}()_,=@#$%^&*]){2,40}$/,
        },

        expiryDate: {
            pattern: /^([0-9]{2})\/([0-9]{2}|[0-9]{4})$/,
        },
    };

    constructor(
        private httpClient: HttpClient,
        private config: SosCoreConfig,
        private sosApiService: SosApiService,
        private store: Store
    ) {}

    /**
     * Get all user notifications -  this is temporary
     * @param path
     * @param params
     * @returns
     */
    public getAll(): Observable<any> {
        return this.sosApiService.get('client/validations');
    }

    public validate(key, value) {
        let validation: boolean | undefined = true;

        switch (key) {
            case 'fname':
                validation = this.getPattern('register', 'first_name').test(
                    value
                );
                break;
            case 'lname':
                validation = this.getPattern('register', 'last_name').test(
                    value
                );
                break;
            case 'addressLine1':
                validation = this.getPattern(
                    'addresses',
                    'address_line_one'
                ).test(value);
                break;
            case 'addressLine2':
                if (value.indexOf('<') >= 0 || value.indexOf('>') >= 0) {
                    validation = false;
                }
                break;
            case 'descriptionText':
                if (value.indexOf('<') >= 0 || value.indexOf('>') >= 0) {
                    validation = false;
                }
                break;
            case 'email':
                validation = this.getPattern('register', 'email').test(value);
                break;
            case 'phone':
                validation = this.getPattern('phones', 'value').test(value);
                break;
            case 'password':
                validation = this.getPattern('register', 'password').test(
                    value
                );
                break;
            case 'postcode':
                validation = this.getPattern('addresses', 'postcode').test(
                    value
                );
                break;
            case 'cardNumber':
                // TODO
                break;
            case 'cvvCode':
                validation = this.customPatterns.cvvCode.pattern.test(value);
                break;
            case 'nameOnCard':
                validation = this.customPatterns.nameOnCard.pattern.test(value);
                break;
            case 'expiryDate':
                validation = this.customPatterns.expiryDate.pattern.test(value);
                break;
            case 'termsAndConditions':
                validation = value;
                break;
            case 'birthDate':
                validation = this.getPattern('register', 'birth_date').test(value);
                break;
        }

        return validation;
    }

    /**
     * Get regex pattern
     * @param searchPath
     * @param searchPattern
     * @returns {{}}
     */
    public getPattern(searchPath, searchPattern): RegExp {
        return this.store.selectSnapshot(
            ValidationsSelectors.getPattern(searchPath, searchPattern)
        );
    }

}
