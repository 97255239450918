import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselSliderComponent } from './carousel-slider/carousel-slider.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [CarouselSliderComponent],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    CarouselSliderComponent
  ]
})
export class CarouselSliderModule { }
