import { TreatsService } from './treats.service';
import { TreatsActions } from './treats.actions';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ErrorActions } from '../../../common';

export interface TreatsStateModel {
  treats: TreatsStateModel[];
}

const treatsDefault: TreatsStateModel = {
  treats: [],
};

@State<TreatsStateModel>({
  name: 'TREATS',
  defaults: treatsDefault,
})
@Injectable()
export class TreatsState {
  constructor(private treatsService: TreatsService) {}

  @Action(TreatsActions.FetchAll)
  fetchAll({ patchState }: StateContext<TreatsStateModel>) {
    return this.treatsService.fetchTreats().pipe(
      tap(
        (response) => {
          patchState({ treats: [...response.data] });
        },
        (error) => {
          patchState({ treats: [] });
        }
      )
    );
  }

  @Action(TreatsActions.Reset)
  reset(ctx: StateContext<TreatsStateModel>) {
    ctx.setState({ ...treatsDefault });
  }
}
