import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    SimpleChanges,
} from '@angular/core';
import Cleave from 'cleave.js';
import {
    AbstractControl,
    ControlValueAccessor,
    NgModel,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { generatorQuerySelector } from '../../../../helpers';

@Directive({
    selector: '[serviceosNgInput]',
    exportAs: 'TextFieldValues',
    providers: [
        NgModel,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputDirective,
            multi: true,
        },
        // {
        //     provide: NG_VALIDATORS,
        //     useExisting: InputDirective,
        //     multi: true,
        // },
    ],
})
export class InputDirective implements ControlValueAccessor {
    @Input()
    public labelText;

    @Input()
    public errorMessage;

    @Input()
    public error;

    @Input()
    public specialFieldType:
        | 'date-picker'
        | 'decimal'
        | 'birth-date'
        | undefined;

    @Input()
    public serviceosNgInput;

    @Input() set ngModel(value: any) {
        if (value && value !== '') {
            this.el.nativeElement.value = value;
        }
    }

    @Input()
    public disableInput = false;

    private errorElement: HTMLElement | undefined;
    private labelElement: HTMLElement | undefined;

    private inputParent;
    private errorParent;

    _onChange = (_: any) => {};

    _onTouched = () => {};

    constructor(private el: ElementRef) {
        this.specifyTypeField();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    writeValue(value: any): void {
        this.el.nativeElement.value = value;
        this.inputStatusUpdate();
        this._onChange(value);
    }

    /**
     * Listener for change of input data
     */
    public ngAfterContentInit() {
        const parent: HTMLElement = this.el.nativeElement.parentElement;

        if (this.disableInput) {
            parent.classList.add('sos-disabled');
            this.el.nativeElement.disabled = true;
        }

        this.specifyTypeField();
    }

    // /**
    //  * Validation method
    //  * @param control
    //  */
    // public validate(control: AbstractControl): { [key: string]: any } | null {
    //     // fix the extra symbol
    //     if (control && control.value) {
    //         // Remove extra symbol for date-picker
    //         if (
    //             this.specialFieldType &&
    //             this.specialFieldType === 'date-picker'
    //         ) {
    //             if (control.value.length > 5) {
    //                 control.setValue(control.value.slice(0, 5));
    //             }
    //         }

    //         if (
    //             this.specialFieldType &&
    //             this.specialFieldType === 'birth-date'
    //         ) {
    //             if (control.value.length > 10) {
    //                 control.setValue(control.value.slice(0, 10));
    //             }
    //         }
    //     }

    //     this.ngOnChanges({});

    //     return null;
    // }

    /**
     * Set different restrictions for each special type
     */
    private specifyTypeField(): void {
        switch (this.specialFieldType) {
            case 'date-picker':
                {
                    this.el.nativeElement.classList.add('date-input-picker');
                    const exp = new Cleave(
                        this.el.nativeElement,
                        {
                            date: true,
                            datePattern: ['d', 'm'],
                            onValueChanged: (input) => {
                                if (input?.target?.value) {
                                    this._onChange(input.target.value);
                                }
                                // this.cdRef.detectChanges();
                            },
                        }
                    );
                }
                break;
            case 'decimal':
                {
                    this.el.nativeElement.classList.add('decimal-input');
                    const decimal = new Cleave(
                        this.el.nativeElement,
                        {
                            numeral: true,
                            numeralDecimalMark: '.',
                            stripLeadingZeroes: false,
                            delimiter: ' ',
                            numeralPositiveOnly: true,
                            // numeralThousandsGroupStyle: 'wan',
                            onValueChanged: (input) => {
                                if (input?.target?.value) {
                                    this._onChange(input.target.value);
                                }
                            },
                        }
                    );
                }
                break;
            case 'birth-date':
                {
                    this.el.nativeElement.classList.add('birth-date-input');
                    const birthDate = new Cleave(
                        this.el.nativeElement,
                        {
                            date: true,
                            delimiter: '-',
                            datePattern: ['Y', 'm', 'd'],
                            onValueChanged: (input) => {
                                if (input?.target?.value) {
                                    this._onChange(input.target.value);
                                }
                            },
                            // delimiter: '-',
                            // blocks: [4, 2, 2]
                        }
                    );
                }
                break;
        }
    }

    /**
     * Focus listener
     */
    @HostListener('focus')
    public onfocus(): void {
        const parent: HTMLElement = this.el.nativeElement.parentElement;
        parent.classList.add('sos-focus');
        parent.classList.add('sos-selected');
    }

    @HostListener('input')
    public change() {
        if (!this.specialFieldType) {
            this._onChange(this.el.nativeElement.value);
        }
    }

    /**
     * Blur listener
     */
    @HostListener('blur')
    public onblur(): void {
        const parent: HTMLElement = this.el.nativeElement.parentElement;
        if (this.el.nativeElement.value === '' && !this.error) {
            parent.classList.remove('sos-focus');
        }
        parent.classList.remove('sos-selected');
    }

    /**
     * Keypress listener
     * @param event
     */
    @HostListener('keypress', ['$event'])
    public onKeypress(event: any): void {
        if (this.disableInput) {
            event.preventDefault();
        }
    }

    @HostListener('change', ['$event'])
    public onChange(event: any): void {
        // console.log(event);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.inputStatusUpdate();
    }

    public inputStatusUpdate() {
        const parent: HTMLElement = this.el.nativeElement.parentElement;

        if (
            this.el.nativeElement.value !== '' ||
            document.activeElement === this.el.nativeElement
        ) {
            parent.classList.add('sos-focus');
        } else {
            parent.classList.remove('sos-focus');
        }

        this.showError();

        if (this.disableInput) {
            parent.classList.add('sos-disable');
            this.el.nativeElement.disable = true;
        } else {
            parent.classList.remove('sos-disable');
            this.el.nativeElement.disable = false;
        }

        if (this.labelElement) {
            this.labelElement.textContent = this.labelText;
        }

        if (this.errorElement) {
            this.errorElement.textContent = this.errorMessage;
        }
    }

    /**
     * Error handler method
     */
    private showError(): void {
        const parent: HTMLElement = this.el.nativeElement.parentElement;
        const errorParent = this.el.nativeElement.parentElement;
        if (this.error) {
            parent.classList.add('sos-error');

            errorParent.classList.add('sos-error');
            parent.classList.add('sos-focus');
        } else {
            errorParent.classList.remove('sos-error');
            parent.classList.remove('sos-error');
        }
    }
}
