import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SosDealCardComponent } from './deal-card/deal-card.component';



@NgModule({
  declarations: [
    SosDealCardComponent
  ],
  imports: [
    CommonModule
  ], 
  exports: [SosDealCardComponent]
})
export class SosDealCardModule { }
