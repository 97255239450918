import { AccountService } from './account.service';
import { TreatsState } from './treats/treats.state';
import { State, Action, StateContext, NgxsOnInit, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AccountActions } from './account.actions';
import { tap, switchMap } from 'rxjs/operators';
import { FeaturesActions } from '../../common/stores/core-store/features/features.actions';
import { NotificationsState } from './notifications/notifications.state';
import { CreditActivityState } from './credit-activity/credit-activity.state';
import { AccountSelectors } from './account.selectors';
import { LocalizationActions } from '../../common/stores/core-store/localization/localization.actions';

export class AccountModel {
    account: any = null;
    referral_stats: any = null;
    payment_methods: any = null;
    status?: 'pending' | 'error' | 'success' = 'pending';
}

export const ACCOUNT_TOKEN = 'ACCOUNT';

@State<AccountModel>({
    name: ACCOUNT_TOKEN,
    defaults: new AccountModel(),
    children: [TreatsState, NotificationsState, CreditActivityState],
})
@Injectable()
export class AccountState implements NgxsOnInit {
    constructor(private accountService: AccountService, private store: Store) {}

    ngxsOnInit(ctx: StateContext<any>) {
        ctx.dispatch(new FeaturesActions.Register(ACCOUNT_TOKEN));
        ctx.dispatch(new AccountActions.Fetch());

        this.store.select(AccountSelectors.shortcodes).subscribe((response) => {
            this.store.dispatch(
                new LocalizationActions.SetShortcodes({ client: response })
            );
        });
    }

    @Action(AccountActions.Initialize)
    Initialize({ dispatch }: StateContext<AccountState>) {}

    @Action(AccountActions.Fetch)
    fetch(ctx: StateContext<AccountModel>) {
        return this.accountService.fetch().pipe(
            tap(
                (response) => {
                    ctx.patchState({
                        account: response.data,
                        status: 'success',
                    });

                    ctx.dispatch(new AccountActions.FetchPaymentMethods());
                },
                (error) => {
                    ctx.patchState({
                        status: 'error',
                    });
                }
            )
        );
    }

    @Action(AccountActions.updateLanguage)
    updateLanguage(
        { patchState, getState, dispatch }: StateContext<AccountModel>,
        payload: AccountActions.updateLanguage
    ) {
        const sendData = {
            language: payload.language ? payload.language : '',
        };

        return this.accountService.updateLanguage(sendData).pipe(
            switchMap((res) => {
                return dispatch(new AccountActions.Fetch());
            })
        );
    }

    @Action(AccountActions.updateName)
    updateName(
        { patchState, getState, dispatch }: StateContext<AccountModel>,
        payload: AccountActions.updateName
    ) {
        const sendData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
        };

        return this.accountService.updateName(sendData).pipe(
            switchMap((res) => {
                return dispatch(new AccountActions.Fetch());
            })
        );
    }

    @Action(AccountActions.updatePassword)
    updatePassword(
        { patchState, getState, dispatch }: StateContext<AccountModel>,
        payload: AccountActions.updatePassword
    ) {
        const sendData = {
            old_password: payload.oldPassword,
            new_password: payload.newPassword,
            confirm_new_password: payload.confirmNewPassword,
        };

        return this.accountService.updatePassword(sendData).pipe(
            switchMap((res) => {
                return dispatch(new AccountActions.Fetch());
            })
        );
    }

    @Action(AccountActions.updateBirthdate)
    updateBirthdate(
        { patchState, getState, dispatch }: StateContext<AccountModel>,
        payload: AccountActions.updateBirthdate
    ) {
        const sendData = {
            birth_date_formatted: payload.birthdate,
        };

        return this.accountService.updateBirthdate(sendData).pipe(
            switchMap((res) => {
                return dispatch(new AccountActions.Fetch());
            })
        );
    }

    @Action(AccountActions.updateAvatar)
    updateAvatar(
        { dispatch }: StateContext<AccountModel>,
        payload: AccountActions.updateAvatar
    ) {
        const sendData = {
            token: payload.token,
        };

        return this.accountService.updateAvatar(sendData).pipe(
            tap((result) => {
                dispatch(new AccountActions.getAvatar());
            })
        );
    }

    @Action(AccountActions.getAvatar)
    getAvatar(
        { patchState, getState }: StateContext<AccountModel>,
        payload: AccountActions.getAvatar
    ) {
        const state = getState();
        return this.accountService.getAvatar().pipe(
            tap((result) => {
                patchState({
                    account: { ...state.account, avatar: result.data },
                });
            })
        );
    }

    @Action(AccountActions.uploadFile)
    uploadFile(
        { dispatch }: StateContext<AccountModel>,
        payload: AccountActions.uploadFile
    ) {
        const sendData = new FormData();
        sendData.append('file', payload.file, payload.file.name);

        return this.accountService.uploadFile(sendData).pipe(
            tap((result) => {
                dispatch(
                    new AccountActions.updateAvatar(payload, result.data.token)
                );
            })
        );
    }

    /**
     * Create address action
     * @param param
     * @returns
     */
    @Action(AccountActions.CreateAddress)
    createAddress(
        { getState, patchState }: StateContext<AccountModel>,
        payload: AccountActions.CreateAddress
    ) {
        return this.accountService.createAddress(payload.address).pipe(
            tap((res) => {
                const state = getState();

                let newAddresses: Array<any> =
                    state.account.addresses && state.account.addresses.length
                        ? state.account.addresses.slice()
                        : [];

                newAddresses.push(res);

                patchState({
                    account: { ...state.account, addresses: newAddresses },
                });
            })
        );
    }

    /**
     * Fetch addresses action
     * @param param
     * @returns
     */
    @Action(AccountActions.FetchAddresses)
    fetchAddresses({ getState, patchState }: StateContext<AccountModel>) {
        return this.accountService.fetchAddresses().pipe(
            tap((addresses) => {
                const state = getState();

                patchState({
                    account: { ...state.account, addresses: addresses },
                });
            })
        );
    }

    /**
     * Create address action
     * @param param
     * @returns
     */
    @Action(AccountActions.DeleteAddress)
    deleteAddress(
        { getState, patchState }: StateContext<AccountModel>,
        payload: AccountActions.DeleteAddress
    ) {
        return this.accountService.deleteAddress(payload.address).pipe(
            tap((res) => {
                const state = getState();

                let newAddresses: Array<any> =
                    state.account.addresses && state.account.addresses.length
                        ? state.account.addresses
                              .slice()
                              .filter(
                                  (address) => address.id !== payload.address.id
                              )
                        : [];

                patchState({
                    account: { ...state.account, addresses: newAddresses },
                });
            })
        );
    }

    /**
     * Mark address as default action hanbdler
     * @param param
     * @returns
     */
    @Action(AccountActions.MarkAddressAsDefault)
    markAddressAsDefault(
        { dispatch }: StateContext<AccountModel>,
        payload: AccountActions.MarkAddressAsDefault
    ) {
        return this.accountService.markAddressAsDefault(payload.address).pipe(
            tap((res) => {
                dispatch(new AccountActions.FetchAddresses());
            })
        );
    }

    /**
     * Fetch phones action
     * @param param
     * @returns
     */
    @Action(AccountActions.FetchPhones)
    fetchPhones({ getState, patchState }: StateContext<AccountModel>) {
        return this.accountService.fetchPhones().pipe(
            tap((phones) => {
                const state = getState();

                patchState({
                    account: { ...state.account, phones: phones },
                });
            })
        );
    }

    /**
     * Create phone action
     * @param param
     * @returns
     */
    @Action(AccountActions.CreatePhone)
    createPhone(
        { getState, patchState }: StateContext<AccountModel>,
        payload: AccountActions.CreatePhone
    ) {
        return this.accountService.createPhone(payload.phone).pipe(
            tap((res) => {
                const state = getState();

                let newPhones: Array<any> =
                    state.account.phones && state.account.phones.length
                        ? state.account.phones.slice()
                        : [];

                newPhones.push(res);

                patchState({
                    account: { ...state.account, phones: newPhones },
                });
            })
        );
    }

    /**
     * Create phone action
     * @param param
     * @returns
     */
    @Action(AccountActions.DeletePhone)
    deletePhone(
        { getState, patchState }: StateContext<AccountModel>,
        payload: AccountActions.DeletePhone
    ) {
        return this.accountService.deletePhone(payload.phone).pipe(
            tap((res) => {
                const state = getState();

                let newPhones: Array<any> =
                    state.account.phones && state.account.phones.length
                        ? state.account.phones
                              .slice()
                              .filter(
                                  (address) => address.id !== payload.phone.id
                              )
                        : [];

                patchState({
                    account: { ...state.account, phones: newPhones },
                });
            })
        );
    }

    /**
     * Mark phone as default action hanbdler
     * @param param
     * @returns
     */
    @Action(AccountActions.MarkPhoneAsDefault)
    markPhoneAsDefault(
        { dispatch }: StateContext<AccountModel>,
        payload: AccountActions.MarkPhoneAsDefault
    ) {
        return this.accountService.markPhoneAsDefault(payload.phone).pipe(
            tap((res) => {
                dispatch(new AccountActions.FetchPhones());
            })
        );
    }

    /**
     * Fetch phones action
     * @param param
     * @returns
     */
    @Action(AccountActions.FetchPreferences)
    fetchPreferences({ getState, patchState }: StateContext<AccountModel>) {
        return this.accountService.fetchPreferences().pipe(
            tap((preferences) => {
                const state = getState();

                patchState({
                    account: { ...state.account, preferences: preferences },
                });
            })
        );
    }

    /**
     * Update user preferences
     * @param param
     * @returns
     */
    @Action(AccountActions.UpdatePreferences)
    updatePreferences(
        { dispatch }: StateContext<AccountModel>,
        payload: AccountActions.UpdatePreferences
    ) {
        return this.accountService.updatePreferences(payload.preferences).pipe(
            tap((res) => {
                dispatch(new AccountActions.FetchPreferences());
            })
        );
    }

    /**
     * Request delete account
     * @param param
     * @returns
     */
    @Action(AccountActions.RequestDeleteAccount)
    requestDeleteAccount(
        { dispatch }: StateContext<AccountModel>,
        payload: AccountActions.RequestDeleteAccount
    ) {
        return this.accountService.requestDeleteAccount(payload.data).pipe(
            switchMap((res) => {
                return dispatch(new AccountActions.Fetch());
            })
        );
    }

    /**
     * Create paymethod action
     * @param param
     * @returns
     */
    @Action(AccountActions.DeletePaymethod)
    deletePaymethod(
        { getState, patchState }: StateContext<AccountModel>,
        payload: AccountActions.DeletePaymethod
    ) {
        return this.accountService.deletePaymethod(payload.paymethod).pipe(
            tap((res) => {
                const state = getState();

                let newPaymethods: Array<any> =
                    state.account.paymethods && state.account.paymethods.length
                        ? state.account.paymethods
                              .slice()
                              .filter(
                                  (paymethod) =>
                                      paymethod.id !== payload.paymethod.id
                              )
                        : [];

                patchState({
                    account: { ...state.account, paymethods: newPaymethods },
                });
            })
        );
    }

    /**
     * Fetch paymethods action
     * @param param
     * @returns
     */
    @Action(AccountActions.FetchPaymethods)
    fetchPaymethods({ getState, patchState }: StateContext<AccountModel>) {
        return this.accountService.fetchPaymethods().pipe(
            tap((paymethods) => {
                const state = getState();

                patchState({
                    account: { ...state.account, paymethods: paymethods },
                });
            })
        );
    }

    /**
     * Fetch paymethods action
     * @param param
     * @returns
     */
    @Action(AccountActions.CreatePaymethod)
    createPaymethod(
        { getState, patchState }: StateContext<AccountModel>,
        payload: AccountActions.CreatePaymethod
    ) {
        return this.accountService.createPaymethod(payload.paymethod).pipe(
            tap((paymethod) => {
                const state = getState();

                const newPaymethods: Array<any> =
                    state.account.paymethods && state.account.paymethods.length
                        ? state.account.paymethods.slice()
                        : [];

                newPaymethods.push(paymethod);

                patchState({
                    account: { ...state.account, paymethods: newPaymethods },
                });
            })
        );
    }

    /**
     * Mark paymethod as default action hanbdler
     * @param param
     * @returns
     */
    @Action(AccountActions.MarkPaymethodAsDefault)
    markPaymethodAsDefault(
        { dispatch }: StateContext<AccountModel>,
        payload: AccountActions.MarkPaymethodAsDefault
    ) {
        return this.accountService
            .markPaymethodAsDefault(payload.paymethod)
            .pipe(
                tap((res) => {
                    dispatch(new AccountActions.FetchPaymethods());
                })
            );
    }

    /**
     * Fetch payment methods action
     * @param param
     * @returns
     */
    @Action(AccountActions.FetchPaymentMethods)
    fetchPaymentMethods({ getState, patchState }: StateContext<AccountModel>) {
        return this.accountService.fetchPaymentMethods().pipe(
            tap((paymentMethods) => {
                const state = getState();

                patchState({
                    account: { ...state.account },
                    payment_methods: paymentMethods,
                });
            })
        );
    }

    @Action(AccountActions.FetchReferralResults)
    fetchCreditsResults({ getState, patchState }: StateContext<AccountModel>) {
        return this.accountService.fetchReferralStats().pipe(
            tap((res) => {
                const state = getState();
                patchState({
                    ...state,
                    referral_stats: res,
                });
            })
        );
    }

    @Action(AccountActions.Reset)
    reset(ctx: StateContext<AccountModel>) {
        const resetAccount = new AccountModel();
        resetAccount.status = 'success';
        ctx.patchState({ ...resetAccount });
    }
}
