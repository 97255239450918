

export namespace ThemeActions {
    export class Initialize {
        static readonly type = '[THEME] Initialize';
    }
    export class ApplyThemeColors {
        static readonly type = '[THEME] Apply Theme Colors';
    }

    export class SaveThemeColors {
        static readonly type = '[THEME] Save Theme Colors';
        constructor(public theme: any) {

        }
    }
}
