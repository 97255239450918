import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  Choice,
  ChoiceItem,
  ChoiceItemValueChange,
} from '../../../../../typings';
import { ChoiceValidationService } from '../../choice-validation.service';

@Component({
  selector: 'serviceos-ng-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultComponent implements OnInit {
  @Output()
  public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

  @Input()
  public parentSettings!: Choice | ChoiceItem | undefined;

  @Input()
  public settings!: Choice | ChoiceItem;

  constructor(private choiceValidationService: ChoiceValidationService) {}

  ngOnInit(): void {
  }
}
