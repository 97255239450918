<div class="deal-card" [ngClass]="{'expand': expand}">
    <div class="deal-image">
        <img height="100%" width="100%" alt="" [src]="dealsConfig.image_url">
    </div>
    <div class="deal-body" (click)="expandHandler($event)">
        <div class="deal-title">
            <span class="deal-title-text" >{{dealsConfig.title}}</span>
            <span class="deal-title-icon"><i class="arrow"></i></span>
        </div>
        <div class="deal-description">
            {{
            dealsConfig.description
            }}
        </div>
    </div>
    <button class="deal-btn" (click)="selectHandler()">
        {{dealsConfig.button_title}}
    </button>
</div>