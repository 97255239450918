import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'serviceos-ng-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
