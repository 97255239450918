export namespace ExpiringDataActions {
    export class Initialize {
        static readonly type = '[EXPIRING_DATA] Initialize';
        constructor() {}
    }

    export class SetProperty {
        static readonly type = '[EXPIRING_DATA] Set Property';
        /**
         * ! Important if validity is not correct format will not set the property.
         * @param propName 
         * @param value 
         * @param validity  Pass string represent the time you want to add to current date.Valid passed data examples: '1d' , '1d,3h' '3h' , 'forever' (cant be only deleted will never expire) 
         * @param storeIn 
         */
        constructor(public propName, public value, public validity: 'forever' | string = 'forever' , public storeIn: 'global' | 'application' = 'application') {}
    }

    export class RemoveProperty {
        static readonly type = '[EXPIRING_DATA] Remove Property';
        constructor(public propName) {}
    }

    export class UpdateProperty {
        static readonly type = '[EXPIRING_DATA] Update Property';
        constructor(public propName, public value) {}
    }

    export class Update {
        static readonly type = '[EXPIRING_DATA] Update';
        constructor() {}
    }
}
