import { SidebarComponent } from './sidebar/sidebar.component';
import { take } from 'rxjs/operators';
import { DialogComponent } from './dialog/dialog.component';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
    ComponentFactoryResolver,
    Injectable,
    Injector,
    Type,
} from '@angular/core';
import { ModalOverlayRef } from './modal-overlay-ref';
import { PageComponent } from './page/page.component';
import { DefaultModalConfig } from '../../models';
import { DefaultModalComponent } from '../default-modal/default-modal/default-modal.component';

export interface ModalConfig {
    class?: string;
    componentData?: any;
    backdropClickClose?: boolean;
    close?: boolean;
    type?: 'dialog' | 'sidebar' | 'page';
}

const DEFAULT_CONFIG: ModalConfig = {
    type: 'dialog',
    close: true,
    backdropClickClose: false,
};

@Injectable({
    providedIn: null,
})
export class ModalsService {
    // Inject overlay service
    constructor(private overlay: Overlay, private injector: Injector) {}

    open(component: Type<any> | DefaultModalConfig, config: ModalConfig = {}): ModalOverlayRef {
        
        if (component instanceof DefaultModalConfig) {
            config.componentData = component;
        }
        
        // Override default configuration
        const dialogConfig = { ...DEFAULT_CONFIG, ...config };

        // Returns an OverlayRef (which is a PortalHost)
        const overlayRef = this.createOverlay(dialogConfig);

        // Create ComponentPortal that can be attached to a PortalHost
        const modalPortal = this.createComponentPortal(dialogConfig);

        // can be move to modalRef;
        if (dialogConfig.backdropClickClose) {
            overlayRef.backdropClick().subscribe((_) => modalRef.close());
        }

        // Attach ComponentPortal to PortalHost
        const componentRef = overlayRef.attach(modalPortal);

        // Instantiate remote control
        const modalRef = new ModalOverlayRef(
            overlayRef,
            componentRef.instance,
            dialogConfig.componentData
        );

        componentRef.instance.config = dialogConfig;
        componentRef.instance.modalRef = modalRef;

        if (component instanceof DefaultModalConfig) {
            componentRef.instance.contentComponentType = DefaultModalComponent;
        } else {
            componentRef.instance.contentComponentType = component;
        }

        componentRef.changeDetectorRef.detectChanges();

        return modalRef;
    }

    private createComponentPortal(config: ModalConfig) {
        switch (config.type) {
            case 'sidebar':
                return new ComponentPortal<SidebarComponent>(SidebarComponent, null , this.injector);
                break;
            case 'page':
                return new ComponentPortal<PageComponent>(PageComponent, null , this.injector);
                break;
            default:
                return new ComponentPortal<DialogComponent>(DialogComponent, null , this.injector);
                break;
        }
    }

    private createOverlay(config: ModalConfig) {
        // Returns an OverlayConfig
        const overlayConfig = this.getOverlayConfig(config);

        // Returns an OverlayRef
        return this.overlay.create(overlayConfig);
    }

    private getOverlayConfig(config: ModalConfig): OverlayConfig {
        
        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.type != 'page',
            backdropClass: ['modal-backdrop', `${config.type}-modal-backdrop`],
            panelClass: config.type + '-modal-panel',
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });

        return overlayConfig;
    }
}
