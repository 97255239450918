import { ErrorCodeType, ERROR_CODES } from './error.const';
import { Selector, createSelector } from '@ngxs/store';
import { ErrorState, ErrorStateModel } from './error.state';

export class ErrorSelectors {
    static authenticationCodes = [
        ERROR_CODES.BookingProcess
            .Authorization_required_to_confirm_booking_transaction,
        ERROR_CODES.Common.Unauthorized,
        ERROR_CODES.BookingProcess.Client_mismatch,
    ];

    @Selector([ErrorState])
    static error(state: ErrorStateModel) {
        return state;
    }

    @Selector([ErrorState])
    static authenticationError(state: ErrorStateModel) {
        if (state.errorType?.code) {
            const authError = ErrorSelectors.authenticationCodes.find(
                (error) => {
                    return error.code === state.errorType?.code;
                }
            );

            if (authError) {
                return state;
            }
            return null;
        }
        return null;
    }

    static checkForSpecificErrors(errors: ErrorCodeType[]) {
        return createSelector([ErrorState], (state: ErrorStateModel) => {
            if (state.errorType?.code) {
                const isSomeOfProvideErrors = errors.find((error) => {
                    return (
                        error.code === (state.errorType as ErrorCodeType).code
                    );
                });

                if (isSomeOfProvideErrors) {
                    return state;
                }
            }
            return null;
        });
    }
}
